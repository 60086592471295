import React, { Component, Fragment } from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, GroupItem } from "devextreme-react/form";
import { JournalsGrid } from "./_JournalsGrid";

export class Journals extends Component {
    static displayName = Journals.name;

    render() {
        return (
            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Jurnale contabile">
                        <Item>
                            <JournalsGrid />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
            </Fragment>
        )
    }
}

export default Journals;