import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import Accordion, { Item } from 'devextreme-react/accordion';
import Menu, { Item }  from 'devextreme-react/menu';
//import './Accordation.css';
import './Menu.css';
import './Drawer.css';

class NavigationList extends Component {
    render() {
        return (
            <div className="list" >
                <Menu
                    orientation='vertical'>
                    <Item title="Rapoarte">
                        <div className="menuDiv" width="100%">
                            <Link className="menuLink" style={{ textDecoration: 'none' }} to="/reports">Rapoarte</Link>
                        </div>
                    </Item>
                    <Item title="Parteneri">
                        <div className="menuDiv">
                            <Link className="menuLink" style={{ textDecoration: 'none' }} to="/company-partners">Parteneri</Link>
                        </div>
                    </Item>
                    <Item title="Produse">
                        <div className="menuDiv">
                            <Link className="menuLink" style={{ textDecoration: 'none' }} to="/company-products">Produse</Link>
                        </div>
                    </Item>
                    <Item title="Companiile mele">
                        <div className="menuDiv">
                            <Link className="menuLink" style={{ textDecoration: 'none' }} to="/my-companies">Companiile mele</Link>
                        </div>
                    </Item>
                    <Item title="Utilizatori">
                        <div className="menuDiv">
                            <Link className="menuLink" style={{ textDecoration: 'none' }} to="/users">Utilizatori</Link>
                        </div>
                    </Item>
                </Menu>
            </div>
        );
    }
}

export default NavigationList;
