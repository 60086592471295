import { handleErrors, isNotEmpty, getCurrentCompanyFromLocalStorage } from "Utils/Utils";
import CustomStore from 'devextreme/data/custom_store';

const productsDataSource = () => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if(!getCurrentCompanyFromLocalStorage())
            {
                return Promise.resolve()
                .then(response => {
                        return {
                            data: [],
                            totalCount: 0
                        };
                    }
                ).catch(err => err);
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if(i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companies/${getCurrentCompanyFromLocalStorage()}/products${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/products/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        },
        insert: (values) => {
            if(!getCurrentCompanyFromLocalStorage())
            {
                return Promise.resolve()
                .then(response => {
                        return {
                            data: [],
                            totalCount: 0
                        };
                    }
                ).catch(err => err);
            }
            
            return fetch(`/api/companies/${getCurrentCompanyFromLocalStorage()}/products`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
                .then(response => {
                    return response
                })
        },
        update: (key, values) => {
            return fetch("/api/products/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch("/api/products/" + key, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export default productsDataSource;