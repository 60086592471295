import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, RequiredRule, CompareRule, ButtonItem, PatternRule, StringLengthRule } from "devextreme-react/form";
import notify from 'devextreme/ui/notify';
import { Redirect } from 'react-router';

export class ForgotPassword extends Component {

    constructor(props) {
        super(props)

        this.state = {
            redirect: false
        }
        this.formRef = React.createRef();
        this.state = {
            token: null
        }
        this.formRef = React.createRef()
        this.compareRule = (e) => {
            return this.formRef.current.instance.getEditor("NewPassword").option("value");
        }

        this.submitButtonOptions = {
            text: "Change password",
            onClick: (e) => {
                this.submitButtonOnClick(e);
            }
        }

        this.submitButtonOnClick = (e) => {
            var formData = this.formRef.current.instance.option("formData");
            formData["Token"] = this.state.token;
            fetch("/passwords/password", {
                method: "PUT",
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                switch (response.status) {
                    case 200:
                        notify("Password was reset successfuly! You will be redirected to the login page shortly", "success", 7000);
                        this.setState({
                            redirect: true
                        });
                        break;
                    case 400:
                        notify("The link is not valid", "error", 3000)
                        break;
                    case 404:
                        notify("The link is not valid or has already been used", "error", 3000)
                        break;
                    default:
                        break;
                }

            })
        }
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to="/Autentificare" />
            )
        }
        return (
            <div className="vertical-element">
                <Form
                    ref={this.formRef}
                    showColonAfterLabel={true}
                    labelLocation="left"
                    colCount={1}
                >
                    <Item
                        dataField="NewPassword"
                        editorType="dxTextBox"
                        editorOptions={{ mode: "password" }}
                        label={{ text: "New password" }}
                    >
                        <RequiredRule message="Password must not be empty" />
                        <PatternRule pattern='\w*\d\w*' message="Password must contain at least one digit" />
                        <PatternRule pattern='\w*[A-Z]\w*' message="Password must contain at least one capital letter" />
                        <PatternRule pattern='\w*[!#$%&()+-/:;@[\]^_|~]\w*' message="Password must contain at least one non-alphanumeric character" />
                        <PatternRule pattern='\w*[^\s]\w*' message="Password must not contain spaces" />
                        <StringLengthRule min={8} max={24} message="Password must have between 8 and 24 characters" />
                    </Item>
                    <Item
                        editorType="dxTextBox"
                        editorOptions={{ mode: "password" }}
                        label={{ text: "Confirm password" }}
                    >
                        <CompareRule comparisonTarget={this.compareRule} />
                    </Item>
                    <ButtonItem horizontalAlignment="center" buttonOptions={this.submitButtonOptions} />
                </Form>
            </div>
        )
    }

    componentDidMount() {
        const { token } = this.props;
        this.setState({
            token: token
        })
    }
}