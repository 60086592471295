import React, { Component } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, ScrollView } from 'devextreme-react';
import { ButtonItem, EmptyItem, Item, RequiredRule, Label, EmailRule, StringLengthRule } from "devextreme-react/form";
import { PatternRule } from 'devextreme-react/data-grid';
import { handleErrors } from 'Utils/Utils';
import dxTextBox from 'devextreme/ui/text_box';
import { getCitiesDataSource, getCountiesDataSource, getCountriesDataSource, getAddressTypesDataSource } from 'Utils/CommonDataSources';

export class CompanyAddressGridEditPopup extends Component {
    constructor(props){
        super(props);
        this.popupRef = React.createRef();
        this.formRef = React.createRef();
        this.state = {
            grdRef: null,
            addressId: 0,
            addressObject: null,
            companyId: 0
        }

        this.setData = false;

        this.editButtonOptions = () => {
            return {
                text: "Save",
                onClick: (e) => {
                    var instance = this.formRef.current.instance;
                    if (!instance.validate().isValid) {
                        return;
                    }

                    var formData = instance.option("formData")
                    return fetch("/api/companies/" + this.props.companyId + "/addresses/" + this.props.addressId, {
                        method: "PUT",
                        body: JSON.stringify(formData),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => response.json())
                    .then(response => {
                        this.formRef.current.instance.resetValues();
                        this.state.grdRef.current.instance.refresh();
                        this.popupRef.current.instance.hide();
                    })
                }
            }
        }

        this.setFormData = (addressObject) => {
            this.setData = true;
            var form = this.formRef.current.instance;
            form.getEditor("StreetName").option("value", addressObject.StreetName);
            form.getEditor("Number").option("value", addressObject.Number);
            form.getEditor("AdditionalAddressDetail").option("value", addressObject.AdditionalAddressDetail);
            form.getEditor("Building").option("value", addressObject.Building);
            form.getEditor("PostalCode").option("value", addressObject.PostalCode);
            form.getEditor("CountryId").option("value", addressObject.CountryId);
            form.getEditor("RegionId").option("value", addressObject.RegionId);
            form.getEditor("CityId").option("value", addressObject.CityId);
            form.getEditor("AddressTypeId").option("value", addressObject.AddressTypeId);
            this.setData = false;
        }

        this.countrySelectBoxOptions =  {
            displayExpr: "Name",
            valueExpr: "Id",
            dataSource: getCountriesDataSource(),
            noDataText: "Nu exista date",
            searchExpr: "Name",
            searchEnabled: "true",
            searchMode: "contains",
            searchTimeout: "200",
            minSearchLength: "0",
            showDataBeforeSearch: "false"
        }

        this.countySelectBoxOptions = {
            displayExpr: "Name",
            valueExpr: "Id",
            dataSource: getCountiesDataSource(),
            noDataText: "Nu exista date",
            searchExpr: "Name",
            searchEnabled: "true",
            searchMode: "contains",
            searchTimeout: "200",
            minSearchLength: "0",
            showDataBeforeSearch: "false"
        }

        this.citySelectBoxOptions = {
            displayExpr: "Name",
            valueExpr: "Id",
            dataSource: getCitiesDataSource(),
            noDataText: "Nu exista date",
            searchExpr: "Name",
            searchEnabled: "true",
            searchMode: "contains",
            searchTimeout: "200",
            minSearchLength: "0",
            showDataBeforeSearch: "false"
        }

        this.addressTypeSelectBoxOptions = {
            displayExpr: "RomanianName",
            valueExpr: "Id",
            dataSource: getAddressTypesDataSource(),
            noDataText: "Nu exista date",
            searchExpr: "RomanianName",
            searchEnabled: "true",
            searchMode: "contains",
            searchTimeout: "200",
            minSearchLength: "0",
            showDataBeforeSearch: "false"
        }

    }

    render() {
        return (
            <div>
                <Popup
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    ref={this.popupRef}
                    title="Detalii adresa"
                    minWidth="20em"
                    maxWidth="40em"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <Form
                            ref={this.formRef}
                            showColonAfterLabel={true}
                            labelLocation="left"
                            colCount={1}>
                                <Item
                                    dataField="CountryId"
                                    caption="Tara"
                                    editorType="dxSelectBox"
                                    editorOptions={this.countrySelectBoxOptions}
                                >
                                    <Label text="Tara" />
                                    <RequiredRule message="Camp obligatoriu!" />
                                </Item>
                                <Item
                                    dataField="RegionId"
                                    caption="Regiune"
                                    editorType="dxSelectBox"
                                    editorOptions={this.countySelectBoxOptions}
                                >
                                    <Label text="Regiune" />
                                </Item>
                                <Item
                                    dataField="CityId"
                                    caption="Oras"
                                    editorType="dxSelectBox"
                                    editorOptions={this.citySelectBoxOptions}
                                >
                                    <Label text="Oras" />
                                    <RequiredRule message="Camp obligatoriu!" />
                                </Item>
                                <Item
                                    dataField="PostalCode"
                                    caption="Cod postal">
                                    <Label text="Cod postal" />
                                    <RequiredRule message="Camp obligatoriu!" />
                                    <StringLengthRule max={18} message="Codul postal trebuie sa aiba maxim 18 de caractere" />
                                </Item>
                                <Item
                                    dataField="StreetName"
                                    caption="Strada">
                                    <Label text="Strada" />
                                    <StringLengthRule max={70} message="Strada trebuie sa aiba maxim 70 de caractere" />
                                </Item>
                                <Item
                                    dataField="Number"
                                    caption="Numar">
                                    <Label text="Numar" />
                                    <StringLengthRule max={18} message="Numarul trebuie sa aiba maxim 18 de caractere" />
                                </Item>
                                <Item
                                    dataField="AdditionalAddressDetail"
                                    caption="Detalii">
                                    <Label text="Detalii" />
                                    <StringLengthRule max={70} message="Detaliile trebuie sa aiba maxim 70 de caractere" />
                                </Item>
                                <Item
                                    dataField="Building"
                                    caption="Cladire">
                                    <Label text="Cladire" />
                                    <StringLengthRule max={35} message="Cladirea trebuie sa aiba maxim 35 de caractere" />
                                </Item>
                                <Item
                                    dataField="AddressTypeId"
                                    caption="Tip adresa"
                                    editorType="dxSelectBox"
                                    editorOptions={this.addressTypeSelectBoxOptions}
                                >
                                    <Label text="Tip adresa" />
                                </Item>
                                <EmptyItem />
                                <ButtonItem 
                                    horizontalAlignment="right"
                                    buttonOptions={this.editButtonOptions()}
                                />
                        </Form>
                    </ScrollView>
                </Popup>
            </div>
        )
    }

    componentDidMount(props) {
        const { grdRef, companyId, addressId, addressObject } = this.props
        this.setState({
            grdRef: grdRef,
            companyId: companyId,
            addressId: addressId,
            addressObject: addressObject
        })
        this.popupRef.current.instance.show();
        this.setFormData(addressObject);
    }

    componentDidUpdate(prevProps){
        const { grdRef, companyId, addressId, addressObject } = prevProps;
        if(grdRef !== this.props.grdRef || companyId !== this.props.companyId || addressId !== this.props.addressId){
            this.setState({
                grdRef: grdRef,
                companyId: companyId,
                addressId: addressId,
                addressObject: addressObject
            })
        }
        this.popupRef.current.instance.show();
        if(!this.setData){
            if(this.formRef.current !== null){
                this.setFormData(addressObject);
            }
        }
    }
}