import CustomStore from 'devextreme/data/custom_store';
import { isNotEmpty, handleErrors, getCurrentCompanyFromLocalStorage } from "Utils/Utils";
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';

function addFilterToDataSource(loadOptions){
    if(loadOptions["searchValue"]){
        loadOptions["filter"] = [
            loadOptions["searchExpr"],
            loadOptions["searchOperation"],
            loadOptions["searchValue"]
        ];
    }
}

export function  getCountriesDataSource() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"Name",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/country${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/country/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function  getCountiesDataSource() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"Name",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/county${params}`) // (`/api/country/${countryId ? countryId : 0}/county${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/county/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function  getCitiesDataSource() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"Name",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/city${params}`)//(`/api/counties/${countyId ? countyId : 0}/cities${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/city/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getAddressTypesDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/addresstype${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/addresstype/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getReportStatusesDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/reportstatuses${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/reportstatuses/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getXmlVersionsDataSource(){
    return new CustomStore({
        key: "Id",
        sort: ["Name"],
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"Name",
                    desc: true
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/reportxmlversions${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/reportxmlversions/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getReportPeriodTypesDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/reportperiodtypes${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/reportperiodtypes/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getTaxAccountingBasisDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/taxaccountingbasis${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/taxaccountingbasis/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getCurrenciesDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"Code",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/currency${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/currency/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getReportHeaderCommentsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);
            
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/reportheadercomments${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/reportheadercomments/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getPartenersDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if(!getCurrentCompanyFromLocalStorage())
            {
                return Promise.resolve()
                .then(response => {
                        return {
                            data: [],
                            totalCount: 0
                        };
                    }
                ).catch(err => err);
            }

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"Company.Name",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if(i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companies/${getCurrentCompanyFromLocalStorage()}/partners${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/companypartners/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getPartenersCompanyAndCurrentCompanyMergedDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if(!getCurrentCompanyFromLocalStorage())
            {
                return Promise.resolve()
                .then(response => {
                        return {
                            data: [],
                            totalCount: 0
                        };
                    }
                ).catch(err => err);
            }

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"Name",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if(i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companies/${getCurrentCompanyFromLocalStorage()}/partnerscompanyandcurrentcompany${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/companies/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getAnalyticalAccountsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"AccountId",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/generalledgeraccount${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/generalledgeraccount/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getInvoiceTypesDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/invoicetypes${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/invoicetypes/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getSelfBillingIndicatorsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/selfbillingindicator${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/selfbillingindicator/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getUOMsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"UnitOfMeasure",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/uom${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/uom/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getDebitCreditIndicatorsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/debitcreditindicators${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/debitcreditindicators/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getGoodsServicesIndicatorsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            let params = "?";
            [
                "skip",     
                "take",  
                "sort", 
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/goodsservicesindicators${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/goodsservicesindicators/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getCurrentCompanyProductsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if(!getCurrentCompanyFromLocalStorage())
            {
                return Promise.resolve()
                .then(response => {
                        return {
                            data: [],
                            totalCount: 0
                        };
                    }
                ).catch(err => err);
            }

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"ProductCode",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if(i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companies/${getCurrentCompanyFromLocalStorage()}/products${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/products/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getReportShortTransactionsDataSource(reportId){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"TransactionId",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/reports/${reportId}/shorttransactions${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/transactions/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getTaxTypesDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"Code",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/taxtypes${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/taxtypes/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getTaxCodesDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"SaftTaxCode",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/taxcodes${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/taxcodes/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getPaymentMethodsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/paymentmethods${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/paymentmethods/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export async function getReportDefaultCurrencyIdAsync(reportId){
    const response = await fetch('/api/reports/' + reportId + '/default-currency-id');
    return await response.json();
}

export async function getLatestXmlVersionIdAsync(){
    const response = await fetch('/api/latest-xml-version-id');
    return await response.json();
}

export function getProductCommodityCodesDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            if(!loadOptions["sort"]){
                loadOptions["sort"] = [{
                    selector:"Name",
                    desc: false
                }];
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/productcommoditycode${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/productcommoditycode/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

export function getValuationMethodsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            addFilterToDataSource(loadOptions);

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/valuationmethod${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/valuationmethod/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}