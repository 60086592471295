import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, RequiredRule, EmailRule, ButtonItem, StringLengthRule, AsyncRule, PatternRule, CompareRule } from "devextreme-react/form";
import notify from 'devextreme/ui/notify';

export class RegisterForm extends Component {
    static displayName = RegisterForm.name;

    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        }
        this.formRef = React.createRef();

        this.userNameTextOptions = {
            mode: "email"
        }

        this.passwordTextOptions = {
            mode: "password"
        }

        this.submitButtonOptions = {
            text: "Inregistrare",
            width: "13em",
            onClick: (e) => {
                this.submitButtonOnClick(e);
            }
        }

        this.submitButtonOnClick = (e) => {
            var validation = this.formRef.current.instance.validate();
            if (validation.isValid) {
                var formData = this.formRef.current.instance.option("formData");
                fetch("/account/register", {
                    method: "POST",
                    body: JSON.stringify(formData),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.status === 201) {
                        this.formRef.current.instance.resetValues();
                        notify("A fost trimis un e-mail la adresa specificata. Pentru a va confirma contul, va rugam sa urmati instructiunile incluse in mesaj.", "success", 5000)
                    }
                    else {
                        notify("Eroare la inregistrare", "error", 3000)
                    }
                })
            }
        }

        this.emailValidation = (e) => {
            return fetch(`/api/users/checkemail?emailAddress=${e.value}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }

        this.compareRule = (e) => {
            return this.formRef.current.instance.getEditor("Password").option("value");
        }
    }

    render() {
        return (
            <Form
                className="Register-form"
                ref={this.formRef}
                showColonAfterLabel={true}
                labelLocation="left"
                colCount={1}
            >
                <Item
                    dataField="FirstName"
                    editorType="dxTextBox"
                    label={{ text: "Prenume" }}
                >
                    <RequiredRule message="Prenumele nu trebuie să fie gol" />
                    <StringLengthRule min={1} max={32} message="Prenumele trebuie să aiba cel mult 32 de caractere" />
                </Item>
                <Item
                    dataField="LastName"
                    editorType="dxTextBox"
                    label={{ text: "Nume" }}
                >
                    <RequiredRule message="Numele de familie nu trebuie să fie gol" />
                    <StringLengthRule min={1} max={32} message="Numele de familie trebuie să aiba cel mult 32 de caractere" />
                </Item>
                <Item
                    dataField="Email"
                    editorType="dxTextBox"
                    editorOptions={this.userNameTextOptions}
                    label={{ text: "Adresa de e-mail" }}
                >
                    <RequiredRule message="Adresa de e-mail nu trebuie sa fie goala" />
                    <AsyncRule message="Adresa de e-mail este deja asociata unui cont" validationCallback={this.emailValidation} />
                    <StringLengthRule min={6} max={32} message="Adresa de e-mail trebuie sa aiba cel mult 32 de caractere" />
                    <EmailRule message="Adresa de e-mail nu are un format corect" />
                </Item>
                <Item
                    dataField="Password"
                    editorType="dxTextBox"
                    editorOptions={this.passwordTextOptions}
                    label={{ text: "Parola" }}
                >
                    <PatternRule pattern='\w*\d\w*' message="Parola trebuie sa contina cel putin o cifra" />
                    <PatternRule pattern='\w*[A-Z]\w*' message="Parola trebuie sa contina cel putin o majuscula" />
                    <PatternRule pattern='\w*[!#$%&()+-/:;@[\]^_|~]\w*' message="Parola trebuie sa contina cel putin un caracter non-alfanumeric" />
                    <PatternRule pattern='\w*[^\s]\w*' message="Parola nu trebuie sa contina spatii" />
                    <RequiredRule message="Parola nu trebuie sa fie goala" />
                    <StringLengthRule min={8} max={24} message="Parola trebuie sa aiba intre 8 si 24 de caractere" />
                </Item>

                <Item
                    dataField="ConfirmPassword"
                    editorType="dxTextBox"
                    editorOptions={this.passwordTextOptions}
                    label={{ text: "Confirm parola" }}
                >
                    {/* <RequiredRule message="Passwords do mot match" /> */}
                    <CompareRule message="Parolele nu se potrivesc" comparisonTarget={this.compareRule} />
                </Item>

                <ButtonItem horizontalAlignment="center" buttonOptions={this.submitButtonOptions} />
            </Form>
        )
    }
}

export default RegisterForm;
