import React, { Component, Fragment } from 'react';
import {Form as GeneralForm, Item, GroupItem } from "devextreme-react/form";
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Pager, Paging,
    Form, Popup, FormItem, StringLengthRule, Lookup, Texts
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { onRowUpdating, isAuditVisible, calculateFilterExpresion } from 'Utils/Utils';
import { getTaxTypesDataSource, getTaxCodesDataSource, getCurrenciesDataSource } from 'Utils/CommonDataSources';
import invoiceLineOrderReferencesDataSource from './InvoiceLineOrderReferences';
import invoiceLineTaxInformationDataSource from './InvoiceLineTaxInformation';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';

export default class InvoiceLineTaxInformationGrid extends Component {

    constructor(props) {
        super(props)
        this.grdRef = React.createRef();
        this.nameRef = React.createRef();
        this.state = {
            InvoiceLineId: props.InvoiceLineId,
            DefaultReportCurrencyId: props.DefaultReportCurrencyId
        }

        //setting taxCodes dataSource to get VATRate when needed
        this.TaxCodesDataSource = null;
        fetch("/api/taxcodes/all-without-pagining", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(response => {
            if(response){
                this.TaxCodesDataSource = response;
            }
        });

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "InvoiceLineTaxInformations");
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setCellValueTaxAmountValue = (newData, value, currentRowData) => {
            newData.TaxAmountValue = value;
            newData.TaxAmountCurrencyAmount = currentRowData.TaxAmountExangeRate * value;
        }

        this.setCellValueTaxAmountExangeRate = (newData, value, currentRowData) => {
            newData.TaxAmountExangeRate = value;
            newData.TaxAmountCurrencyAmount = currentRowData.TaxAmountValue * value;
        }

        this.setStateValueTaxCodeId = (newData, value, currentRowData) => {
            newData.TaxCodeId = value;
            var taxCodeSelected = this.TaxCodesDataSource.find(x => x.Id === value);
            if(taxCodeSelected != null){
                newData.TaxPercentage = this.TaxCodesDataSource.find(x => x.Id === value).VATRate;
            }
        }

        this.onInitNewRow = (e) => {
            e.data.TaxAmountCurrencyId = this.state.DefaultReportCurrencyId;
            e.data.TaxAmountExangeRate = 1;
            e.data.TaxAmountValue = 0;
            e.data.TaxAmountCurrencyAmount = 0;
            e.data.TaxBase = 0;
        }
    }

    render() {
        return (
                            <DataGrid
                                id="invoiceLineTaxInformationGrid"
                                ref={this.grdRef}
                                dataSource={invoiceLineTaxInformationDataSource(this.state.InvoiceLineId)}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                wordWrapEnabled={true}
                                columnAutoWidth={true}
                                focusedRowEnabled={false}
                                width="1400px"
                                height="auto"
                                noDataText="Nu exista inregistrari"
                                remoteOperations={true}
                                onRowUpdating={onRowUpdating} 
                                onInitNewRow={this.onInitNewRow}
                            >
                                <FilterRow visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />

                                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                                    <Form colCount={2} title="Taxa">
                                        <Item itemType="group" colCount={2} colSpan={2}>
                                            <Item dataField="TaxTypeId" />
                                            <Item dataField="TaxCodeId" />
                                            <Item dataField="TaxPercentage" />
                                            <Item dataField="TaxBase" />
                                            <Item dataField="TaxBaseDescription" />
                                        </Item>
                                        <Item itemType="group" caption="Valoare" colCount={2} colSpan={2}>
                                            <Item dataField="TaxAmountValue" />
                                            <Item dataField="TaxAmountCurrencyId" />
                                            <Item dataField="TaxAmountExangeRate" />
                                            <Item dataField="TaxAmountCurrencyAmount" />
                                        </Item>
                                        <Item itemType="group" colCount={2} colSpan={2}>
                                            <Item dataField="TaxExemptionReason" />
                                            <Item dataField="TaxDeclarationPeriod" />
                                        </Item>
                                    </Form>
                                    <Popup title="Detalii taxa" showTitle={true} minWidth="40em" maxWidth="80em" height="auto" />
                                    <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                                </Editing>

                                <Column type="buttons" cssClass="vertical-center" width={120}>
                                    <Button name="edit" cssClass="underlineBtn" />
                                    <Button name="delete" cssClass="underlineBtn" />
                                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                                </Column>

                                <Column dataField="Id" alignment="left" allowEditing={false} visible={false}>
                                    <FormItem visible={false} />
                                </Column>
                                <Column dataField="TaxTypeId" caption="Tip" cssClass="vertical-left" alignment="left" minWidth="10em"
                                    calculateSortValue="TaxType.Name" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                        dataSource={getTaxTypesDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="Code"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TaxCodeId" caption="Cod" cssClass="vertical-left" alignment="left" minWidth="10em"
                                    calculateSortValue="TaxCode.Name" editorOptions={{noDataText: "Nu exista date"}}
                                    setCellValue={this.setStateValueTaxCodeId}>
                                    <Lookup
                                        dataSource={getTaxCodesDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="SaftTaxCode"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TaxPercentage" caption="Procent" minWidth="5em" editorType="dxNumberBox" allowEditing={false}>
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TaxBase" caption="Baza pe care se calculeaza impozitul" minWidth="5em" editorType="dxNumberBox">
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TaxBaseDescription" caption="Descriere baza" dataType="string" minWidth="5em">
                                    <StringLengthRule max={70} message="Descrierea trebuie sa aiba maxim 70 de caractere" />
                                </Column>
                                <Column dataField="TaxAmountValue" caption="Valoare" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="TaxAmount.Value" setCellValue={this.setCellValueTaxAmountValue}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("TaxAmount.Value", selectedFilterOperations, filterValue)}}>
                                    <StringLengthRule max={70} message="Numarul trebuie sa aiba maxim 70 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TaxAmountCurrencyId" caption="Moneda" minWidth="5em"
                                    calculateSortValue="TaxAmount.Currency.Code" editorOptions={{noDataText: "Nu exista date"}}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("TaxAmount.CurrencyId", selectedFilterOperations, filterValue)}}>
                                    <Lookup
                                            dataSource={getCurrenciesDataSource()}
                                            valueExpr="Id" 
                                            displayExpr="Code"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TaxAmountExangeRate" caption="Curs de schimb" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="TaxAmount.ExangeRate" setCellValue={this.setCellValueTaxAmountExangeRate}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("TaxAmount.ExangeRate", selectedFilterOperations, filterValue)}}>
                                        <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TaxAmountCurrencyAmount" caption="Valoare in valuta" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="TaxAmount.CurrencyAmount"
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("TaxAmount.CurrencyAmount", selectedFilterOperations, filterValue)}}>
                                        <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TaxExemptionReason" caption="Motiv scutire/ reducere" dataType="string" minWidth="5em">
                                    <StringLengthRule max={70} message="Motivul trebuie sa aiba maxim 70 de caractere" />
                                </Column>
                                <Column dataField="TaxDeclarationPeriod" caption="Identificare declaratie raportare suma" dataType="string" minWidth="5em">
                                    <StringLengthRule max={35} message="Identificarea trebuie sa aiba maxim 35 de caractere" />
                                </Column>

                            </DataGrid>
        );
    }
}