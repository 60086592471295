/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Form, ScrollView } from 'devextreme-react';
import { GroupItem } from 'devextreme-react/form';
import { CompanySelection } from './CompanySelection/_CompanySelection';
import { getCurrentCompanyFromLocalStorage } from 'Utils/Utils';


export class UserMenu extends Component {
    static displayName = UserMenu.name;

    constructor(props){
        super(props);

        this.state = {
            companyId: getCurrentCompanyFromLocalStorage()
        }

        this.setCompanyState = (value) => {
            this.setState({
                companyId: value
            })
        }
    }

    render() {
        return (
            <div className="user-menu">

                <Form colCount={1}>
                    <GroupItem
                        caption="Companies"
                        colCount={1}
                        colSpan={1}
                    >
                        <CompanySelection setCompany={this.setCompanyState} />
                    </GroupItem>
                </Form>

                <div id="userMenuAuditPopupContainer" />
            </div>
        )
    }
}