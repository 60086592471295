import React, {Component} from 'react';
import { Item } from "devextreme-react/form";
import ReactDOM from 'react-dom';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { onRowUpdating, isAuditVisible } from "Utils/Utils";
import { getAnalyticalAccountsDataSource, getPartenersDataSource } from 'Utils/CommonDataSources';
import { DataGrid } from 'devextreme-react';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    FormItem,
    Form,
    Popup,
    StringLengthRule,
    Texts,
    NumericRule,
    RangeRule,
    Lookup,
    FilterRow,
    SearchPanel,
    Paging,
    Pager,
    Scrolling
} from 'devextreme-react/data-grid';
import partnersBalancesDataSource from './PartnersBalancesData';

export class PartnersBalancesGrid extends Component {
    constructor(props){
        super(props);
        this.grdPartnersBalancesRef = React.createRef();

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "PartnerBalances");
        }

        this.ReportId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

        this.onInitNewRow = (e) => {
            e.data.HasDebitBalance = true;
            e.data.HasCreditBalance = false;
            e.data.OpeningDebitBalance = 0;
            e.data.ClosingDebitBalance = 0;
        }

        this.onRowValidating = (e) => {
            var hasDebitBalance = e.newData.HasDebitBalance != undefined ? e.newData.HasDebitBalance : e.oldData.HasDebitBalance;
            var hasCreditBalance = e.newData.HasCreditBalance != undefined ? e.newData.HasCreditBalance : e.oldData.HasCreditBalance;
            var openingDebitBalance = e.newData.OpeningDebitBalance != undefined ? e.newData.OpeningDebitBalance : (e.oldData ? e.oldData.OpeningDebitBalance : null);
            var closingDebitBalance = e.newData.ClosingDebitBalance != undefined ? e.newData.ClosingDebitBalance : (e.oldData ? e.oldData.ClosingDebitBalance : null);
            var openingCreditBalance = e.newData.OpeningCreditBalance != undefined ? e.newData.OpeningCreditBalance : (e.oldData ? e.oldData.OpeningCreditBalance : null);
            var closingCreditBalance = e.newData.ClosingCreditBalance != undefined ? e.newData.ClosingCreditBalance : (e.oldData ? e.oldData.ClosingCreditBalance : null);

            if(hasDebitBalance){
                if((!openingDebitBalance && openingDebitBalance != 0) || (!closingDebitBalance && closingDebitBalance != 0)){
                    e.errorText = "Deoarece este setata bifa 'Are sold debit', trebuie completate valorile de la sold debit";
                    e.isValid = false;
                }
            }
            else if(hasCreditBalance){
                if((!openingCreditBalance && openingCreditBalance != 0) || (!closingCreditBalance && closingCreditBalance != 0)){
                    e.errorText = "Deoarece este setata bifa 'Are sold credit', trebuie completate valorile de la sold credit";
                    e.isValid = false;
                }
            }
        }

        this.setCellValueHasDebitBalance = (newData, value, currentRowData) => {
            newData.HasDebitBalance = value;
            newData.HasCreditBalance = !value;
            if(value){
                newData.OpeningDebitBalance = 0;
                newData.ClosingDebitBalance = 0;
                newData.OpeningCreditBalance = null;
                newData.ClosingCreditBalance = null;
            }else{
                newData.OpeningDebitBalance = null;
                newData.ClosingDebitBalance = null;
                newData.OpeningCreditBalance = 0;
                newData.ClosingCreditBalance = 0;
            }
        }

        this.setCellValueHasCreditBalance = (newData, value, currentRowData) => {
            newData.HasCreditBalance = value;
            newData.HasDebitBalance = !value;
            if(value){
                newData.OpeningCreditBalance = 0;
                newData.ClosingCreditBalance = 0;
                newData.OpeningDebitBalance = null;
                newData.ClosingDebitBalance = null;
            }else{
                newData.OpeningCreditBalance = null;
                newData.ClosingCreditBalance = null;
                newData.OpeningDebitBalance = 0;
                newData.ClosingDebitBalance = 0;
            }
        }
    }

    render() {
        return(
                <DataGrid
                    ref={this.grdPaymentsRef}
                    width="100%"
                    dataSource={partnersBalancesDataSource(this.ReportId)}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    height="100%"
                    noDataText="Nu exista inregistrari"
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                    onInitNewRow={this.onInitNewRow} 
                    onRowValidating={this.onRowValidating}
                >
                    <FilterRow visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />
                    <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                        <Form colCount={2} title="Sold partener">
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item dataField="CompanyPartnerId" />
                                <Item dataField="GeneralLedgerAccountId" />
                            </Item>
                            <Item itemType="group" caption="" colCount={2} colSpan={2}>
                                <Item dataField="HasDebitBalance"/>
                                <Item />
                                <Item dataField="OpeningDebitBalance" />
                                <Item dataField="ClosingDebitBalance" />
                                <Item dataField="HasCreditBalance"/>
                                <Item />
                                <Item dataField="OpeningCreditBalance" />
                                <Item dataField="ClosingCreditBalance" />
                            </Item>
                        </Form>
                        <Popup title="Detalii sold partener" showTitle={true} minWidth="40em" maxWidth="80em" height="auto" />
                    </Editing>
                    <Column type="buttons" width={120}>
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible}/>
                        <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="CompanyPartnerId" caption="Partener" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="CompanyPartner.Company.Name" editorOptions={{noDataText: "Nu exista date"}}>
                                <Lookup
                                    dataSource={getPartenersDataSource()}
                                    valueExpr="Id" 
                                    displayExpr="Name"
                                    searchExpr="Company.Name"
                                />
                                <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="GeneralLedgerAccountId" caption="Cont contabil" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="GeneralLedgerAccount.AccountId" editorOptions={{noDataText: "Nu exista date"}}>
                            <Lookup
                                dataSource={getAnalyticalAccountsDataSource()}
                                valueExpr="Id" 
                                displayExpr="AccountId"
                            />
                            <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="HasDebitBalance" caption="Are sold debit"
                        visible={false} allowEditing={true} editorType="dxCheckBox"
                        setCellValue={this.setCellValueHasDebitBalance}>
                        <FormItem visible={true} />
                    </Column>
                    <Column dataField="OpeningDebitBalance" caption="Valoare debit de deschidere" editorType="dxNumberBox" minWidth="5em">
                        <StringLengthRule max={20} message="Numarul trebuie sa aiba maxim 20 de caractere" />
                    </Column>
                    <Column dataField="ClosingDebitBalance" caption="Valoare debit de inchidere" editorType="dxNumberBox" minWidth="5em">
                        <StringLengthRule max={20} message="Numarul trebuie sa aiba maxim 20 de caractere" />
                    </Column>
                    <Column dataField="HasCreditBalance" caption="Are sold credit"
                        visible={false} allowEditing={true} editorType="dxCheckBox"
                        setCellValue={this.setCellValueHasCreditBalance}>
                        <FormItem visible={true} />
                    </Column>
                    <Column dataField="OpeningCreditBalance" caption="Valoare credit de deschidere" editorType="dxNumberBox" minWidth="5em">
                        <StringLengthRule max={20} message="Numarul trebuie sa aiba maxim 20 de caractere" />
                    </Column>
                    <Column dataField="ClosingCreditBalance" caption="Valoare credit de inchidere" editorType="dxNumberBox" minWidth="5em">
                        <StringLengthRule max={20} message="Numarul trebuie sa aiba maxim 20 de caractere" />
                    </Column>
                </DataGrid>
        )
    }
}