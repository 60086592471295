import React, {Component, Fragment} from 'react';
import {Form as GeneralForm, Item, GroupItem } from "devextreme-react/form";
import ReactDOM from 'react-dom';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { onRowUpdating, isAuditVisible, multiLineCell } from "Utils/Utils";
import { DataGrid } from 'devextreme-react';
import transactionsDataSource from './TransactionsData';
import { getPartenersCompanyAndCurrentCompanyMergedDataSource } from 'Utils/CommonDataSources';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    EmailRule,
    FormItem,
    Form,
    Popup,
    CustomRule,
    StringLengthRule,
    Texts,
    Lookup,
    FilterRow,
    Scrolling,
    MasterDetail,
    NumericRule,
    RangeRule,
    SearchPanel,
    Paging,
    Pager
} from 'devextreme-react/data-grid';
import { TransactionLinesGrid } from '../TransactionLines/_TransactionLinesGrid';

export class TransactionsGrid extends Component {
    constructor(props){
        super(props);
        this.grdTransactionsRef = React.createRef();
        this.state = {
            JournalId: props.JournalId,
            DefaultReportCurrencyId: props.DefaultReportCurrencyId
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Transactions");
        }

        this.getCurrentYear = () => {
            return new Date().getFullYear();
        }
    }

    render() {
        return(
            <Fragment>
                <GeneralForm colCount={1}>
                    <GroupItem caption="Tranzactii">
                        <Item>
                            <DataGrid
                                ref={this.grdTransactionsRef}
                                dataSource={transactionsDataSource(this.state.JournalId)}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                wordWrapEnabled={true}
                                columnAutoWidth={true}
                                height="100%"
                                width="auto"
                                noDataText="Nu exista inregistrari"
                                remoteOperations={true}
                                onRowUpdating={onRowUpdating}
                            >
                                <FilterRow visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />
                                <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                                    <Form colCount={2} title="Tranzactie" />
                                    <Popup title="Detalii tranzactie" showTitle={true} minWidth="40em" maxWidth="80em" height="auto" />
                                </Editing>
                                <MasterDetail
                                    enabled={true} 
                                    component={props => {
                                        return <TransactionLinesGrid TransactionId={props.data.key} DefaultReportCurrencyId={this.props.DefaultReportCurrencyId} />
                                    }}
                                />
                                <Column type="buttons" width={120} minWidth="10em">
                                    <Button name="edit" cssClass="underlineBtn" />
                                    <Button name="delete" cssClass="underlineBtn" />
                                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                                    <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                                </Column>
                                <Column dataField="Id" visible={false} allowEditing={false}>
                                    <FormItem visible={false} />
                                </Column>
                                <Column dataField="TransactionId" caption="ID tranzactie" dataType="string" minWidth="5em">
                                    <StringLengthRule max={70} message="ID-ul tranzactiei trebuie sa aiba maxim 70 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="Period" caption="Perioada" minWidth="10em" editorType="dxNumberBox" editorOptions={{format: "#"}}>
                                    <NumericRule message="Valoarea trebuie sa fie numerica!" />
                                    <RangeRule min={0} max={1000} message="Valoarea trebuie sa fie intre 0 si 1000" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="PeriodYear" caption="An perioada" minWidth="10em" editorType="dxNumberBox" editorOptions={{format: "#"}}>
                                    <NumericRule message="Valoarea trebuie sa fie numerica!" />
                                    <RangeRule min={2020} max={this.getCurrentYear()} message="Valoarea trebuie sa fie cuprinsa intre 2020 si anul curent" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TransactionDate" caption="Data tranzactie" minWidth="10em"
                                    dataType="date" format="dd.MM.yyyy">
                                        <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="SourceId" caption="ID sursa" dataType="string" minWidth="10em">
                                    <StringLengthRule max={35} message="ID-ul sursei trebuie sa aiba maxim 35 de caractere" />
                                </Column>
                                <Column dataField="TransactionType" caption="Tip tranzactie" dataType="string" minWidth="10em">
                                    <StringLengthRule max={35} message="Tipul tranzactiei trebuie sa aiba maxim 35 de caractere" />
                                </Column>
                                <Column dataField="Description" caption="Descriere" dataType="string" minWidth="10em"
                                    cellRender={function (e) { return multiLineCell(e.data.Description); }} editorType="dxTextArea">
                                    <StringLengthRule max={256} message="Descrierea trebuie sa aiba maxim 256 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="BatchId" caption="ID lot" dataType="string" minWidth="10em">
                                    <StringLengthRule max={35} message="ID-ul filialei trebuie sa aiba maxim 35 de caractere" />
                                </Column>
                                <Column dataField="SystemEntryDate" caption="Data intrarii in sistem" minWidth="10em"
                                    dataType="date" format="dd.MM.yyyy">
                                        <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="GLPostingDate" caption="Data afisarii GL" minWidth="10em"
                                    dataType="date" format="dd.MM.yyyy">
                                        <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="CustomerCompanyId" caption="Cumparator" cssClass="vertical-left" alignment="left" minWidth="10em"
                                    editorOptions={{noDataText: "Nu exista date"}}>
                                            <Lookup
                                                dataSource={getPartenersCompanyAndCurrentCompanyMergedDataSource()}
                                                valueExpr="Id" 
                                                displayExpr="Name"
                                            />
                                            <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="SupplierCompanyId" caption="Vanzator" cssClass="vertical-left" alignment="left" minWidth="10em"
                                    editorOptions={{noDataText: "Nu exista date"}}>
                                            <Lookup
                                                dataSource={getPartenersCompanyAndCurrentCompanyMergedDataSource()}
                                                valueExpr="Id" 
                                                displayExpr="Name"
                                            />
                                            <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="SystemId" caption="ID sistem" dataType="string" minWidth="10em">
                                    <StringLengthRule max={18} message="ID-ul sistemului trebuie sa aiba maxim 18 de caractere" />
                                </Column>
                            </DataGrid>
                        </Item>
                    </GroupItem>
                </GeneralForm>
            </Fragment>
        )
    }
}