import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { LoadIndicator } from 'devextreme-react';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Pager, Paging,
    Form, Popup, FormItem, StringLengthRule, Lookup, Texts
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'custom.css';
import 'Utils/multilineToast.css';
import { onRowUpdating, isAuditVisible, multiLineCell, getCurrentCompanyFromLocalStorage } from 'Utils/Utils';
import dataSourceOptions  from "../../Reports/ReportsData";
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { getReportStatusesDataSource, getXmlVersionsDataSource
    , getTaxAccountingBasisDataSource, getLatestXmlVersionIdAsync, getReportHeaderCommentsDataSource } from 'Utils/CommonDataSources';
import { withRouter } from "react-router";
import notify from 'devextreme/ui/notify';
import { Icon } from '@material-ui/core';
import { APP_RON_CURRENCY_ID } from "constantAppValues";

class ReportsGrid extends Component {
    static displayName = ReportsGrid.name;

    constructor(props) {
        super(props)
        this.grdRef = React.createRef();
        this.nameRef = React.createRef();

        this.toolbarCustomText = getCurrentCompanyFromLocalStorage() ? "" : "Trebuie selectata compania curenta";
        this.displayWarningText = getCurrentCompanyFromLocalStorage() ? "none" : "";

        this.state = {
            keysDisabledForGenerate: [],
            keysDisabledForValidate: []
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Reports");
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.LatestXmlVersionId = null;

        this.renderAddPopup = () => {
            if(getCurrentCompanyFromLocalStorage()){
                this.grdRef.current.instance.addRow();
            }
            else
            {
                notify("Trebuie selectata compania curenta pentru a putea adauga un raport acesteia");
            }
        }

        this.onInitNewRow = (e) => {
            e.data.ReportStatusId = 1;
            var date = new Date();
            var firstDayOfPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            var lastDayOfPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 0);
            e.data.From = firstDayOfPreviousMonth;
            e.data.To = lastDayOfPreviousMonth;
            e.data.DefaultCurrencyId = APP_RON_CURRENCY_ID;
            e.data.XmlReportVersionId = this.LatestXmlVersionId;
        }

        this.goToReportDetails = (e) => {
            if(e.row.data.IsAuditFileGenerated){
                notify("Raportul a fost deja general. Pentru a putea modifica datele, raportul trebuie deblocat.", "info", 6000);
            }
            else if(e.row.data.IsValidatedToGenerateAuditFile){
                notify("Raportul a fost deja validat. Pentru a putea modifica datele, raportul trebuie deblocat.", "info", 6000);
            }
            else{
                let queryString = e.row.data.Id;
                this.props.history.push(`/report-details/${queryString}`);
            }
        }
    
        this.validateReport = (e) => {
            var idReport = e.row.key;

            var updatedKeysDisabled = this.state.keysDisabledForValidate;
            updatedKeysDisabled.push(e.row.key);
            this.setState({
                keysDisabledForValidate: updatedKeysDisabled
            });

            notify("Se valideaza datele raportului");
            fetch("/api/reports/" + idReport + "/is-valid-for-audit-file", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(response => {
                if(response.Success){
                    notify("Raportul a fost validat cu success");
                    if(this.grdRef.current != null)
                        this.grdRef.current.instance.refresh();
                }else{
                    notify("Raportul NU a fost validat cu success\r\n" + response.FailedValidationsMessages.join('\r\n'), "info", 10000);
                    const updatedKeysDisabledForValidate = this.state.keysDisabledForValidate.filter(function(x) {
                        return x !== idReport;
                    });
                    this.setState({
                        keysDisabledForValidate: updatedKeysDisabledForValidate
                    });
                    this.state.keysDisabledForValidate.indexOf(e.row.key)
                    if(this.grdRef.current != null)
                        this.grdRef.current.instance.refresh();
                }
            });
        }
    
        this.generateAuditFile = (e) => {
            if(!e.row.data.IsValidatedToGenerateAuditFile){
                notify("Raportul trebuie mai intai validat");
                return;
            }

            var updatedKeysDisabled = this.state.keysDisabledForGenerate;
            updatedKeysDisabled.push(e.row.key);

            this.setState({
                keysDisabledForGenerate: updatedKeysDisabled
            });

            var idReport = e.row.key;
            notify("Se genereaza fisierul SAF-T");
            fetch("/api/reports/" + idReport + "/generate-audit-file", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(response => {
                if(response){
                    notify("Fisierul SAF-T a fost generat cu succes");
                    if(this.grdRef.current != null)
                        this.grdRef.current.instance.refresh();
                }else{
                    notify("A aparut o eroare la generarea fisierului. Va rugam reincarcati pagina si incercati din nou.");
                }
            });
    
            
        }
    
        this.downloadAuditFile = (e) => {
            var idReport = e.row.key;
            fetch("/api/reports/" + idReport + "/download-audit-file", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/xml'
                }
            })
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "saft-audit-file-" + idReport + ".xml";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again         
            });
        }

        this.unlockReport = (e) => {
            var idReport = e.row.key;
            fetch("/api/reports/" + idReport + "/unlock-audit-file", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(response => {
                if(response){
                    notify("Raportul a fost deblocat");
                    const updatedKeysDisabledForGenerate = this.state.keysDisabledForGenerate.filter(function(x) {
                        return x !== idReport;
                    });
                    this.setState({
                        keysDisabledForGenerate: updatedKeysDisabledForGenerate
                    });

                    const updatedKeysDisabledForValidate = this.state.keysDisabledForValidate.filter(function(x) {
                        return x !== idReport;
                    });
                    this.setState({
                        keysDisabledForValidate: updatedKeysDisabledForValidate
                    });

                    if(this.grdRef.current != null)
                        this.grdRef.current.instance.refresh();
                }else{
                    notify("A aparut o eroare la deblocarea raportului. Va rugam reincarcati pagina si incercati din nou.");
                }
            });
        }
    
        this.isGenerateAuditFileButtonVisible = (e) => {
            if(e.row.data.IsValidatedToGenerateAuditFile && 
                (e.row.data.IsAuditFileGenerated
                || this.state.keysDisabledForGenerate.indexOf(e.row.key) >= 0))
                return false;
            return true;
        }
    
        this.isGenerateAuditFileButtonLoadIndicatorVisible = (e) => {
            if(this.state.keysDisabledForGenerate.indexOf(e.row.key) >= 0
                && !e.row.data.IsAuditFileGenerated){
                return true;
            }
            return false;
        }

        this.isValidateReportButtonVisible = (e) => {
            if(e.row.data.IsValidatedToGenerateAuditFile
                || this.state.keysDisabledForValidate.indexOf(e.row.key) >= 0){
                return false;
            }
            return true;
        }

        this.isValidateReportButtonLoadIndicatorVisible = (e) => {
            if(this.state.keysDisabledForValidate.indexOf(e.row.key) >= 0
                && !e.row.data.IsValidatedToGenerateAuditFile){
                return true;
            }
            return false;
        }

        this.isDownloadAuditFileButtonVisible = (e) => {
            return e.row.data.IsAuditFileGenerated;
        }
    
        this.isUnlockButtonVisible = (e) => {
            return e.row.data.IsValidatedToGenerateAuditFile;
        }
    }

    render() {
        return (
            <div>
                <div className="danger-text" style={{display: this.displayWarningText}}>
                    <label>{this.toolbarCustomText}</label>
                </div>
                <div className="container-datagrid">
                    <DataGrid
                        id="reportsGrid"
                        ref={this.grdRef}
                        dataSource={dataSourceOptions()}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        width="auto"
                        height="auto"
                        noDataText="Nu exista inregistrari"
                        remoteOperations={true}
                        onRowUpdating={onRowUpdating} 
                        onInitNewRow={this.onInitNewRow}
                        onToolbarPreparing={(e) => {
                            e.toolbarOptions.items.unshift({
                                location: "after",
                                widget: "dxButton",
                                options: {
                                    icon: "add",
                                    onClick: (e) => {
                                        this.renderAddPopup();
                                    }
                                }
                            })
                        }}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />

                        <Editing allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                            <Form colCount={1} title="Raport" />
                            <Popup title="Detalii raport" showTitle={true} minWidth="20em" maxWidth="40em" height="auto" />
                            <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                        </Editing>

                        <Column type="buttons" cssClass="vertical-center" width={120}>
                            <Button name="edit" cssClass="underlineBtn" />
                            <Button name="delete" cssClass="underlineBtn" />
                            <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                            <Button
                                icon="link"
                                hint="Detalii"
                                onClick={this.goToReportDetails}
                            />
                        </Column>

                        <Column dataField="Id" caption="Nr." alignment="left" allowEditing={false}>
                            <FormItem visible={false} />
                        </Column>
                        <Column dataField="From" caption="De la data" cssClass="vertical-left" alignment="center" minWidth="10em" dataType="date" format="dd.MM.yyyy"
                            defaultSortIndex={0} defaultSortOrder="asc">
                                <RequiredRule message="Camp obligatoriu" />
                        </Column>
                        <Column dataField="To" caption="Pana la data" cssClass="vertical-left" alignment="center" minWidth="10em" dataType="date" format="dd.MM.yyyy"
                            defaultSortIndex={1} defaultSortOrder="asc" >
                                <RequiredRule message="Camp obligatoriu" />
                        </Column>
                        <Column dataField="TaxAccountingBasisId" visible={false} caption="Baza contabilitatii fiscale" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="TaxAccountingBasis.Code" editorOptions={{noDataText: "Nu exista date"}}>
                                <Lookup
                                    dataSource={getTaxAccountingBasisDataSource()}
                                    valueExpr="Id" 
                                    displayExpr="Code"
                                />
                                <RequiredRule message="Camp obligatoriu" />
                        </Column>
                        <Column dataField="ReportHeaderCommentId" caption="Comentariu antet SAF-T" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="ReportHeaderComment.Name" editorOptions={{noDataText: "Nu exista date"}}>
                            <Lookup
                                    dataSource={getReportHeaderCommentsDataSource()}
                                    valueExpr="Id" 
                                    displayExpr="Name"
                                />
                                <RequiredRule message="Camp obligatoriu" />
                        </Column>
                        <Column dataField="Name" caption="Observatii" cssClass="vertical-left" alignment="left" minWidth="10em" dataType="string"
                            cellRender={function (e) { return multiLineCell(e.data.Name); }} editorType="dxTextArea">
                            <StringLengthRule max={256} message="Observatiile trebuie sa aiba maxim 256 de caractere" />
                        </Column>
                        <Column dataField="XmlReportVersionId" caption="Versiune Xml" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="XmlReportVersion.Name" editorOptions={{noDataText: "Nu exista date"}}>
                                <Lookup
                                    dataSource={getXmlVersionsDataSource()}
                                    valueExpr="Id" 
                                    displayExpr="Name"
                                />
                                <RequiredRule message="Camp obligatoriu" />
                        </Column>
                        <Column dataField="ReportStatusId" caption="Status" cssClass="vertical-left" alignment="left" minWidth="10em" allowEditing={false}
                            calculateSortValue="ReportStatus.Name" editorOptions={{noDataText: "Nu exista date"}}>
                                <Lookup
                                    dataSource={getReportStatusesDataSource()}
                                    valueExpr="Id" 
                                    displayExpr="Name"
                                />
                                <RequiredRule message="Camp obligatoriu" />
                                <FormItem visible={false} />
                        </Column>
                        <Column type="buttons" >
                            <Button
                                text="Valideaza"
                                icon="check"
                                hint="Valideaza"
                                visible={this.isValidateReportButtonVisible}
                                onClick={this.validateReport}
                            />
                            <Button
                                visible={this.isValidateReportButtonLoadIndicatorVisible}>
                                <LoadIndicator id="small-indicator" height={20} width={20} />
                            </Button>
                        </Column>
                        <Column type="buttons">
                            <Button
                                text="Genereaza"
                                icon="print"
                                hint="Genereaza"
                                visible={this.isGenerateAuditFileButtonVisible}
                                onClick={this.generateAuditFile}
                            />
                            <Button
                                visible={this.isGenerateAuditFileButtonLoadIndicatorVisible}>
                                <LoadIndicator id="small-indicator" height={20} width={20} />
                            </Button>
                            <Button
                                text="Descarca"
                                icon="download"
                                hint="Descarca"
                                visible={this.isDownloadAuditFileButtonVisible}
                                onClick={this.downloadAuditFile}
                            />
                        </Column>
                        <Column type="buttons">
                            <Button
                                text="Deblocheaza"
                                icon="undo"
                                hint="Deblocheaza"
                                visible={this.isUnlockButtonVisible}
                                onClick={this.unlockReport}
                            />
                        </Column>
                        <Column dataField="IsAuditFileGenerated" visible={false} allowEditing={false}>
                            <FormItem visible={false} />
                        </Column>
                        <Column dataField="AuditFilePath" visible={false} allowEditing={false}>
                            <FormItem visible={false} />
                        </Column>
                        <Column dataField="IsValidatedToGenerateAuditFile" visible={false} allowEditing={false}>
                            <FormItem visible={false} />
                        </Column>
                        <Column dataField="DefaultCurrencyId" visible={false} allowEditing={false}>
                            <FormItem visible={false} />
                        </Column>
                    </DataGrid>
                </div>
            </div>
        );
    }

    async componentDidMount() {  
        try {
            this.LatestXmlVersionId = await getLatestXmlVersionIdAsync();
        } catch(err) {}
    }
}

export default withRouter(ReportsGrid);