import React, { Component, Fragment } from 'react';
import {Form as GeneralForm, Item, GroupItem } from "devextreme-react/form";
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Pager, Paging,
    Form, Popup, FormItem, StringLengthRule, Lookup, Texts,
    MasterDetail, Scrolling
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { onRowUpdating, isAuditVisible, calculateFilterExpresion, multiLineCell } from 'Utils/Utils';
import { getAnalyticalAccountsDataSource, getUOMsDataSource, getCurrenciesDataSource
    , getDebitCreditIndicatorsDataSource, getGoodsServicesIndicatorsDataSource, getCurrentCompanyProductsDataSource } from 'Utils/CommonDataSources';
import purchaseInvoiceLinesDataSource from './PurchaseInvoiceLinesData';
import InvoiceLineMasterDetail from '../../Shared/_InvoiceLineMasterDetail';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';

export default class PurchaseInvoiceMasterDetail extends Component {

    constructor(props) {
        super(props)
        this.grdRef = React.createRef();
        this.nameRef = React.createRef();
        this.state = {
            PurchaseInvoiceId: props.PurchaseInvoiceId,
            DefaultReportCurrencyId: props.DefaultReportCurrencyId
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "InvoiceLines");
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setCellValueAmountValue = (newData, value, currentRowData) => {
            newData.AmountValue = value;
            newData.AmountCurrency = currentRowData.AmountExangeRate * value;
        }

        this.setCellValueAmountExangeRate = (newData, value, currentRowData) => {
            newData.AmountExangeRate = value;
            newData.AmountCurrency = currentRowData.AmountValue * value;
        }

        this.onInitNewRow = (e) => {
            e.data.AmountCurrencyId = this.state.DefaultReportCurrencyId;
            e.data.AmountExangeRate = 1;
            e.data.AmountValue = 0;
            e.data.AmountCurrency = 0;
        }
    }

    render() {
        return (
            <Fragment>
                <GeneralForm colCount={1}>
                    <GroupItem caption="Linii factura">
                        <Item>
                            <DataGrid
                                id="purchaseInvoiceLinesGrid"
                                ref={this.grdRef}
                                dataSource={purchaseInvoiceLinesDataSource(this.state.PurchaseInvoiceId)}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                wordWrapEnabled={true}
                                columnAutoWidth={true}
                                focusedRowEnabled={false}
                                width="auto"
                                height="auto"
                                noDataText="Nu exista inregistrari"
                                remoteOperations={true}
                                onRowUpdating={onRowUpdating} 
                                onInitNewRow={this.onInitNewRow}
                            >
                                <FilterRow visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />
                                <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />

                                <MasterDetail
                                    enabled={true} 
                                    component={props => {
                                        return <InvoiceLineMasterDetail InvoiceLineId={props.data.key} DefaultReportCurrencyId={this.state.DefaultReportCurrencyId} />
                                    }}
                                />

                                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                                    <Form colCount={2} title="Linie factura">
                                        <Item itemType="group" colCount={2} colSpan={2}>
                                            <Item dataField="LineNumber" />
                                            <Item dataField="AccountId" />
                                            <Item dataField="GoodsServicesIndicatorId" />
                                            <Item dataField="ProductId" />
                                            <Item dataField="Quantity" />
                                            <Item dataField="InvoiceUOMId" />
                                            <Item dataField="UOMToUOMBaseConversionFactor" />
                                            <Item dataField="UnitPrice" />
                                            <Item dataField="TaxPointDate" />
                                            <Item dataField="ReferencesCreditNoteReason" />
                                            <Item dataField="Description" />
                                            <Item dataField="DebitCreditIndicatorId" />
                                        </Item>
                                        <Item itemType="group" caption="Valoare" colCount={2} colSpan={2}>
                                            <Item dataField="AmountValue" />
                                            <Item dataField="AmountCurrencyId" />
                                            <Item dataField="AmountExangeRate" />
                                            <Item dataField="AmountCurrency" />
                                        </Item>
                                    </Form>
                                    <Popup title="Detalii linie factura" showTitle={true} minWidth="40em" maxWidth="80em" height="auto" />
                                    <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                                </Editing>

                                <Column type="buttons" cssClass="vertical-center" width={120}>
                                    <Button name="edit" cssClass="underlineBtn" />
                                    <Button name="delete" cssClass="underlineBtn" />
                                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                                </Column>

                                <Column dataField="Id" alignment="left" allowEditing={false} visible={false}>
                                    <FormItem visible={false} />
                                </Column>

                                <Column dataField="LineNumber" caption="Numar linie" dataType="string" minWidth="5em">
                                    <StringLengthRule max={18} message="Numarul liniei trebuie sa aiba maxim 18 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                <Column dataField="AccountId" caption="Cont contabil" cssClass="vertical-left" alignment="left" minWidth="10em"
                                    calculateSortValue="Account.AccountId" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                        dataSource={getAnalyticalAccountsDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="AccountId"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                {/* <Column dataField="ShipTo" caption="Expediere spre?" dataType="string" minWidth="5em" allowFiltering={false} allowSorting={false}>
                                </Column>

                                <Column dataField="ShipFrom" caption="Expediere de la?" dataType="string" minWidth="5em" allowFiltering={false} allowSorting={false}>
                                </Column> */}

                                <Column dataField="GoodsServicesIndicatorId" caption="Indicator bunuri sau servicii" minWidth="5em"
                                    calculateSortValue="GoodsServicesIndicator.Name" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                            dataSource={getGoodsServicesIndicatorsDataSource()}
                                            valueExpr="Id" 
                                            displayExpr="Name"
                                    />
                                </Column>

                                <Column dataField="ProductId" caption="Produs" dataType="string" minWidth="5em"
                                    calculateSortValue="Product.ProductCode" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                            dataSource={getCurrentCompanyProductsDataSource()}
                                            valueExpr="Id" 
                                            displayExpr="ProductCode"
                                        />
                                </Column>

                                <Column dataField="Quantity" caption="Cantitate" minWidth="5em" editorType="dxNumberBox">
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                <Column dataField="InvoiceUOMId" caption="Unitate de masura" dataType="string" minWidth="5em"
                                    calculateSortValue="InvoiceUOM.UnitOfMeasure" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                        dataSource={getUOMsDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="UnitOfMeasure"
                                    />
                                </Column>

                                <Column dataField="UOMToUOMBaseConversionFactor" caption="Factor conversie U.M. la U.M. de baza" editorType="dxNumberBox" minWidth="5em">
                                </Column>

                                <Column dataField="UnitPrice" caption="Pret unitar" editorType="dxNumberBox" minWidth="5em">
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                <Column dataField="TaxPointDate" caption="Data exigibilitate/ data factura" dataType="date" format="dd.MM.yyyy" minWidth="5em">
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                <Column dataField="ReferencesCreditNoteReference" caption="Referinta facturii de stornare" dataType="string" minWidth="5em">
                                    <StringLengthRule max={35} message="Numarul trebuie sa aiba maxim 35 de caractere" />
                                </Column>

                                <Column dataField="ReferencesCreditNoteReason" caption="Motivul facturii de stornare" dataType="string" minWidth="5em">
                                    <StringLengthRule max={256} message="Numarul trebuie sa aiba maxim 256 de caractere" />
                                </Column>

                                <Column dataField="Description" caption="Descriere" dataType="string" minWidth="5em"
                                    cellRender={function (e) { return multiLineCell(e.data.Description); }} editorType="dxTextArea">
                                    <StringLengthRule max={256} message="Numarul trebuie sa aiba maxim 256 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                <Column dataField="DebitCreditIndicatorId" caption="Indicator debit credit" minWidth="5em"
                                    calculateSortValue="DebitCreditIndicator.Name" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                            dataSource={getDebitCreditIndicatorsDataSource()}
                                            valueExpr="Id" 
                                            displayExpr="Name"
                                    />
                                </Column>

                                <Column dataField="AmountValue" caption="Valoare" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="InvoiceLineAmount.Value" setCellValue={this.setCellValueAmountValue}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("InvoiceLineAmount.Value", selectedFilterOperations, filterValue)}}>
                                    <StringLengthRule max={70} message="Numarul trebuie sa aiba maxim 70 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                <Column dataField="AmountCurrencyId" caption="Moneda" minWidth="5em"
                                    calculateSortValue="InvoiceLineAmount.Currency.Code" editorOptions={{noDataText: "Nu exista date"}}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("InvoiceLineAmount.CurrencyId", selectedFilterOperations, filterValue)}}>
                                    <Lookup
                                            dataSource={getCurrenciesDataSource()}
                                            valueExpr="Id" 
                                            displayExpr="Code"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                <Column dataField="AmountExangeRate" caption="Curs de schimb" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="InvoiceLineAmount.ExangeRate" setCellValue={this.setCellValueAmountExangeRate}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("InvoiceLineAmount.ExangeRate", selectedFilterOperations, filterValue)}}>
                                        <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                <Column dataField="AmountCurrency" caption="Valoare in valuta" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="InvoiceLineAmount.CurrencyAmount"
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("InvoiceLineAmount.CurrencyAmount", selectedFilterOperations, filterValue)}}>
                                        <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                {/* <Column dataField="ShippingCostsAmount" caption="Valoare cost transport?" dataType="string" minWidth="5em" allowFiltering={false} allowSorting={false}>
                                </Column> */}
                                
                            </DataGrid>
                        </Item>
                    </GroupItem>
                </GeneralForm>
            </Fragment>
        );
    }
}