import React, { Component, Fragment } from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, GroupItem } from "devextreme-react/form";
import { PaymentsGrid } from "./_PaymentsGrid";

export class Payments extends Component {
    static displayName = Payments.name;

    render() {
        return (
            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Plati">
                        <Item>
                            <PaymentsGrid />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
            </Fragment>
        )
    }
}

export default Payments;