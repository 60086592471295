import CustomStore from "devextreme/data/custom_store";
import { isNotEmpty, handleErrors } from "Utils/Utils";

export const companyAddressesDataSource = (companyId) => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companies/${companyId ? companyId : 0}/addresses${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/companyaddresses/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        },
        insert: (values) => {
            values.CompanyId = companyId;

            return fetch(`/api/companies/${companyId}/addresses`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        update: (key, values) => {
            return fetch(`/api/companies/${companyId}/addresses/${key}`, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch(`/api/companyaddresses/${key}`, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export const companyContactsDataSource = (companyId) => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companies/${companyId ? companyId : 0}/contacts${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/companycontacts/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        },
        insert: (values) => {
            values.CompanyId = companyId;

            return fetch(`/api/companies/${companyId}/contacts`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        update: (key, values) => {
            return fetch(`/api/companies/${companyId}/contacts/${key}`, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch(`/api/companycontacts/${key}`, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export const companyBankAccountsDataSource = (companyId) => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companies/${companyId ? companyId : 0}/bankaccounts${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/companybankaccounts/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        },
        insert: (values) => {
            values.CompanyId = companyId;

            return fetch(`/api/companies/${companyId}/bankaccounts`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        update: (key, values) => {
            return fetch(`/api/companies/${companyId}/bankaccounts/${key}`, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch(`/api/companybankaccounts/${key}`, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export const companyTaxRegistrationsDataSource = (companyId) => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companies/${companyId ? companyId : 0}/taxregistrations${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/api/companytaxregistrations/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        },
        insert: (values) => {
            values.CompanyId = companyId;

            return fetch(`/api/companies/${companyId}/taxregistrations`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        update: (key, values) => {
            return fetch(`/api/companies/${companyId}/taxregistrations/${key}`, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch(`/api/companytaxregistrations/${key}`, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}