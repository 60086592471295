import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { CompanyAddressesGrid } from './CompanyMasterDetailDataGrids/_CompanyAddressesGrid';
import { CompanyBankAccountsGrid } from './CompanyMasterDetailDataGrids/_CompanyBankAccountsGrid';
import { CompanyContactsGrid } from './CompanyMasterDetailDataGrids/_CompanyContactsGrid';
import { CompanyTaxRegistrationsGrid } from './CompanyMasterDetailDataGrids/_CompanyTaxRegistrationsGrid';

export class CompanyMasterDetail extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            CompanyId: this.props.CompanyId
        }

        this.renderCompanyAddresses = () => { return <CompanyAddressesGrid CompanyId={this.state.CompanyId} /> }
        this.renderCompanyBankAccounts = () => { return <CompanyBankAccountsGrid CompanyId={this.state.CompanyId} /> }
        this.renderCompanyContacts = () => { return <CompanyContactsGrid CompanyId={this.state.CompanyId} /> }
    }

    render(){
        return(
            <div>
                <TabPanel width="95vw">
                    <Item title="Adrese" render={this.renderCompanyAddresses} />
                    <Item title="Conturi bancare" render={this.renderCompanyBankAccounts} />
                    <Item title="Contacte" render={this.renderCompanyContacts} />
                </TabPanel>
            </div>
        )
    }

    componentDidMount(){
        this.setState({
            CompanyId: this.props.CompanyId
        })
    }

    componentDidUpdate(prevProps){
        const {CompanyId} = prevProps;
        if(this.props.CompanyId !== CompanyId){
            this.setState({
                CompanyId: this.props.CompanyId
            })
        }
    }
}