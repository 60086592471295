import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import RegisterForm from "./RegisterForm/_RegisterForm"

export class Register extends Component {
    static displayName = Register.name;

    

    render() {
        return (
            <div className="row my-auto h-100">
                <div className="col-sm-1 col-md-2 col-lg-4 my-auto" />
                <div className="col-sm-10 col-md-8 col-lg-4 my-auto register-container">
                    <RegisterForm />
                </div>
                <div className="col-sm-1 col-md-2 col-lg-4 my-auto" />
            </div>
        )
    }
}

export default Register;
