import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Login } from './components/Authentication/_Login';
import { ConfirmAccount } from 'components/Authentication/_ConfirmAccount';
import { Register } from "./components/Authentication/_Register.js";
import { SetPassword } from 'components/SetPassword/_SetPassword';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { locale } from "devextreme/localization";
import { Users } from './components/Users/_Users';
import { Companies } from './components/Companies/_Companies';
import { Partners } from './components/Partners/_Partners';
import { Products } from 'components/Products/_Products';
import { Reports } from './components/Reports/_Reports';
import { ReportDetails } from 'components/ReportDetails/_ReportDetails';
import './reset.css';
import 'devextreme/dist/css/dx.light.css';
import './custom.css';
import Footer from './components/footer';


export default class App extends Component {
    constructor(props) {
        super(props);
        locale(navigator.language)
    }
    static displayName = App.name;

    render() {
        return (
            <div id="main-div">
                <Layout>
                    <Route exact path='/' component={Home} />
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    <Route path='/Autentificare' component={Login} />
                    <Route path="/resetPassword/:id/:token" component={SetPassword} />
                    <Route path='/confirmaccount/:userId' component={ConfirmAccount} />
                    <Route path="/register" component={Register} />
                    <AuthorizeRoute path="/users" component={Users} />
                    <AuthorizeRoute path="/my-companies" component={Companies} />
                    <AuthorizeRoute path="/company-partners" component={Partners} />
                    <AuthorizeRoute path="/company-products" component={Products} />
                    <AuthorizeRoute path="/reports" component={Reports} />
                    <AuthorizeRoute path="/report-details" component={ReportDetails} />
                </Layout> 
                <Footer/>
            </div>
        );
    }
}



