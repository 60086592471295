import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import { stringify } from 'qs';

export function validatePhoneNumber(e) {
    if (e.value === undefined || e.value === null) {
        return true;
    }
    var pattern = RegExp(/^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.)?([0-9]{3}(\s|\.|)){2}$/, 'i')
    return pattern.test(e.value)
}

export function handleResponse(values) {
    if (values.Status) {
        notify(values.Message, "success", 3000)
    }
    else {
        notify(values.Message, "error", 3000)
    }
}

export function onRowUpdating(options) {
    options.newData = Object.assign(options.oldData, options.newData);
}

export function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}

export function handleErrors(response) {
    if (!response.ok)
        throw Error(response.statusText);
    return response;
}

export function isAuditVisible(e) {
    return !e.row.isEditing;
}

export function getANAFInfo(cui) {
    
    return fetch(`/api/anaf/${cui}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json());
}

export function getClientMiscDataSource(clientId, type) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            return fetch(`/Client/${clientId}/clientmiscs/${type}`)
                .then(response => response.json())
        },
        byKey: (key) => {
            return fetch('/clientmisc/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        insert: (values) => {
            values["ClientId"] = clientId;
            values["Type"] = type;
            return fetch("/ClientMisc", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json());
        },
        update: (key, values) => {
            return fetch("/ClientMisc/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json());
        },
        remove: (key) => {
            return fetch("/ClientMisc/" + key, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
    })
}

export function isNullOrEmpty(str) {
    var returnValue = false;
    if (!str
        || str === null
        || str === 'null'
        || str === ''
        || str === '{}'
        || str === 'undefined'
        || str.length === 0) {
        returnValue = true;
    }
    return returnValue;
}

export function GetCustomDate_yyyy_MM_dd(date) {
    var data = "";

    var month = date.getMonth() + 1;
    if (month <= 9)
        month = "0" + month.toString();

    return data.concat(date.getFullYear(), '-', month, '-', date.getDate().toString(), 'T00:00:00.000Z');
}

export const monthNames = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Iunie",
    "Iulie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"
]

export function GetCustomDate_dd_MM_yyy(date) {
    if (isNullOrEmpty(date)) return "";

    var data = "";
    let parts = date.split('-');

    return data.concat(parts[2].split('T')[0], '.', parts[1], '.', parts[0]);
}

export function calculateFilterExpresion(dataField, operation, value) {
    if (isNullOrEmpty(operation))
        operation = "contains";
console.log(operation);
    if (operation === "between") {
        return [[dataField, ">=", value], "and", [dataField, "<=", value]];
    }
    else {
        if (value instanceof Date) {
            var date = new Date(new Date(value.valueOf()).setDate(value.getDate() + 1));
            return [[dataField, ">=", value], "and", [dataField, "<=", new Date(date.setDate(date.getDate() + 1))]]
        }
        return [dataField, operation, value];
    }
}


export function multiLineCell(text) {
    if(isNullOrEmpty(text))
        return (
            <div></div>
        )
    return (
        <div>
            {
                text.split("\n").map((i, key) => {
                    return <div key={key}>{i}</div>
                })
            }
        </div>
    )
}

export function isValidDate(date) {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export function isValidNumber(number){
    return !Number.isNaN(parseInt(number));
}

export function getCurrentCompanyFromLocalStorage(){
    var companyId = localStorage.getItem("CurrentCompany")
    if(!isValidNumber(companyId)){
        return undefined;
    }

    if(parseInt(companyId) === 0){
        return undefined;
    }

    return parseInt(companyId)
}

export function findElementInArray(array, element){
    for(var i = 0; i<array.length; i+=1){
        if(array[i] === element){
            return array[i];
        }
    }

    return null;
}