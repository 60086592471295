import React, {Component, Fragment} from 'react';
import {Form as GeneralForm, Item, GroupItem } from "devextreme-react/form";
import ReactDOM from 'react-dom';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { validatePhoneNumber, onRowUpdating, isAuditVisible, calculateFilterExpresion, multiLineCell } from "Utils/Utils";
import { DataGrid } from 'devextreme-react';
import transactionLinesDataSource from './TransactionLinesData';
import { getPartenersCompanyAndCurrentCompanyMergedDataSource, getAnalyticalAccountsDataSource, getCurrenciesDataSource } from 'Utils/CommonDataSources';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    EmailRule,
    FormItem,
    Form,
    Popup,
    CustomRule,
    StringLengthRule,
    Texts,
    Lookup,
    FilterRow,
    Scrolling,
    MasterDetail,
    NumericRule,
    RangeRule,
    SearchPanel,
    Paging,
    Pager
} from 'devextreme-react/data-grid';
import TransactionLineTaxInformationGrid from '../TransactionLineTaxInformation/_TransactionLineTaxInformationGrid';

export class TransactionLinesGrid extends Component {
    constructor(props){
        super(props);
        this.grdTransactionLinesRef = React.createRef();
        this.state = {
            TransactionId: props.TransactionId,
            DefaultReportCurrencyId: props.DefaultReportCurrencyId
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "TransactionLines");
        }

        this.onInitNewRow = (e) => {
            e.data.HasDebitAmount = true;
            e.data.DebitAmountCurrencyId = this.state.DefaultReportCurrencyId;
            e.data.DebitAmountExangeRate = 1;
            e.data.DebitAmountValue = 0;
            e.data.DebitAmountCurrencyAmount = 0;

            e.data.HasCreditAmount = false;
            e.data.CreditAmountCurrencyId = null;
            e.data.CreditAmountExangeRate = null;
            e.data.CreditAmountValue = null;
            e.data.CreditAmountCurrencyAmount = null;
        }

        this.onSetCellValueDebitAmountValue = (newData, value, currentRowData) => {
            newData.DebitAmountValue = value;
            newData.DebitAmountCurrencyAmount = currentRowData.DebitAmountExangeRate * value;
        }

        this.setCellValueDebitAmountExangeRate = (newData, value, currentRowData) => {
            newData.DebitAmountExangeRate = value;
            newData.DebitAmountCurrencyAmount = currentRowData.DebitAmountValue * value;
        }

        this.onSetCellValueCreditAmountValue = (newData, value, currentRowData) => {
            newData.CreditAmountValue = value;
            newData.CreditAmountCurrencyAmount = currentRowData.CreditAmountExangeRate * value;
        }

        this.setCellValueCreditAmountExangeRate = (newData, value, currentRowData) => {
            newData.CreditAmountExangeRate = value;
            newData.CreditAmountCurrencyAmount = currentRowData.CreditAmountValue * value;
        }

        this.setCellValueHasDebitAmount = (newData, value, currentRowData) => {
            newData.HasDebitAmount = value;
            newData.HasCreditAmount = !value;
            if(value){
                newData.DebitAmountCurrencyId = this.state.DefaultReportCurrencyId;
                newData.DebitAmountExangeRate = 1;
                newData.DebitAmountValue = 0;
                newData.DebitAmountCurrencyAmount = 0;

                newData.CreditAmountCurrencyId = null;
                newData.CreditAmountExangeRate = null;
                newData.CreditAmountValue = null;
                newData.CreditAmountCurrencyAmount = null;
            }else{
                newData.CreditAmountCurrencyId = this.state.DefaultReportCurrencyId;
                newData.CreditAmountExangeRate = 1;
                newData.CreditAmountValue = 0;
                newData.CreditAmountCurrencyAmount = 0;

                newData.DebitAmountCurrencyId = null;
                newData.DebitAmountExangeRate = null;
                newData.DebitAmountValue = null;
                newData.DebitAmountCurrencyAmount = null;
            }
            
        }

        this.setCellValueHasCreditAmount = (newData, value, currentRowData) => {
            newData.HasCreditAmount = value;
            newData.HasDebitAmount = !value;
            if(value){
                newData.CreditAmountCurrencyId = this.state.DefaultReportCurrencyId;
                newData.CreditAmountExangeRate = 1;
                newData.CreditAmountValue = 0;
                newData.CreditAmountCurrencyAmount = 0;

                newData.DebitAmountCurrencyId = null;
                newData.DebitAmountExangeRate = null;
                newData.DebitAmountValue = null;
                newData.DebitAmountCurrencyAmount = null;
            }
            else{
                newData.DebitAmountCurrencyId = this.state.DefaultReportCurrencyId;
                newData.DebitAmountExangeRate = 1;
                newData.DebitAmountValue = 0;
                newData.DebitAmountCurrencyAmount = 0;

                newData.CreditAmountCurrencyId = null;
                newData.CreditAmountExangeRate = null;
                newData.CreditAmountValue = null;
                newData.CreditAmountCurrencyAmount = null;
            }
        }

        this.onRowValidating = (e) => {
            if(e.newData && e.newData.HasDebitAmount)
            {
                if(e.newData.DebitAmountValue == null
                    || e.newData.DebitAmountCurrencyId == null
                    || e.newData.DebitAmountExangeRate == null
                    || e.newData.DebitAmountCurrencyAmount == null)
                {
                    e.errorText = "Deoarece este setata bifa 'Are valoare debit', trebuie completate valorile de la suma debit";
                    e.isValid = false;
                }
            }
            else if(e.newData && e.newData.HasCreditAmount)
            {
                if(e.newData.CreditAmountValue == null
                    || e.newData.CreditAmountCurrencyId == null
                    || e.newData.CreditAmountExangeRate == null
                    || e.newData.CreditAmountCurrencyAmount == null)
                {
                    e.errorText = "Deoarece este setata bifa 'Are valoare credit', trebuie completate valorile de la suma credit";
                    e.isValid = false;
                }
            }
            else if(e.oldData && e.oldData.HasDebitAmount)
            {
                if(((typeof e.newData.DebitAmountValue != 'undefined' && e.newData.DebitAmountValue == null ) 
                    || (typeof e.newData.DebitAmountValue == 'undefined' && e.oldData.DebitAmountValue == null))
                    || ((typeof e.newData.DebitAmountCurrencyId != 'undefined' && e.newData.DebitAmountCurrencyId == null )
                        || (typeof e.newData.DebitAmountCurrencyId == 'undefined' && e.oldData.DebitAmountCurrencyId == null))
                    || ((typeof e.newData.DebitAmountExangeRate != 'undefined' && e.newData.DebitAmountExangeRate == null)
                        || (typeof e.newData.DebitAmountExangeRate == 'undefined' && e.oldData.DebitAmountExangeRate == null))
                    || ((typeof e.newData.DebitAmountCurrencyAmount != 'undefined' && e.newData.DebitAmountCurrencyAmount == null )
                        || (typeof e.newData.DebitAmountCurrencyAmount == 'undefined' && e.oldData.DebitAmountCurrencyAmount == null)))
                {
                    e.errorText = "Deoarece este setata bifa 'Are valoare debit', trebuie completate valorile de la suma debit";
                    e.isValid = false;
                }
            }
            else if(e.oldData && e.oldData.HasCreditAmount)
            {
                if(((typeof e.newData.CreditAmountValue != 'undefined' && e.newData.CreditAmountValue == null ) 
                            || (typeof e.newData.CreditAmountValue == 'undefined' && e.oldData.CreditAmountValue == null))
                    || ((typeof e.newData.CreditAmountCurrencyId != 'undefined' && e.newData.CreditAmountCurrencyId == null )
                        || (typeof e.newData.CreditAmountCurrencyId == 'undefined' && e.oldData.CreditAmountCurrencyId == null))
                    || ((typeof e.newData.CreditAmountExangeRate != 'undefined' && e.newData.CreditAmountExangeRate == null)
                        || (typeof e.newData.CreditAmountExangeRate == 'undefined' && e.oldData.CreditAmountExangeRate == null))
                    || ((typeof e.newData.CreditAmountCurrencyAmount != 'undefined' && e.newData.CreditAmountCurrencyAmount == null )
                        || (typeof e.newData.CreditAmountCurrencyAmount == 'undefined' && e.oldData.CreditAmountCurrencyAmount == null)))
                {
                    e.errorText = "Deoarece este setata bifa 'Are valoare credit', trebuie completate valorile de la suma credit";
                    e.isValid = false;
                }
            }
        }

    }

    render() {
        return(
            <Fragment>
                <GeneralForm colCount={1}>
                    <GroupItem caption="Linii tranzactie">
                        <Item>
                            <DataGrid
                                ref={this.grdTransactionLinesRef}
                                dataSource={transactionLinesDataSource(this.state.TransactionId)}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                wordWrapEnabled={true}
                                columnAutoWidth={true}
                                height="100%"
                                width="auto"
                                noDataText="Nu exista inregistrari"
                                remoteOperations={true}
                                onRowUpdating={onRowUpdating}
                                onInitNewRow={this.onInitNewRow} 
                                onRowValidating={this.onRowValidating}
                            >
                                <FilterRow visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />
                                <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                                    <Form colCount={2} title="Linie tranzactie">
                                        <Item itemType="group" colCount={2} colSpan={2}>
                                            <Item dataField="RecordId" />
                                            <Item dataField="AccountId" />
                                            <Item dataField="ValueDate" />
                                            <Item dataField="SourceDocumentId" />
                                            <Item dataField="CustomerCompanyId" />
                                            <Item dataField="SupplierCompanyId" />
                                            <Item dataField="Description" />
                                        </Item>
                                        <Item itemType="group" caption="Suma debit" colCount={2} colSpan={2}>
                                            <Item dataField="HasDebitAmount"/>
                                            <Item />
                                            <Item dataField="DebitAmountValue" />
                                            <Item dataField="DebitAmountCurrencyId" />
                                            <Item dataField="DebitAmountExangeRate" />
                                            <Item dataField="DebitAmountCurrencyAmount" />
                                        </Item>
                                        <Item itemType="group" caption="Suma credit" colCount={2} colSpan={2}>
                                            <Item dataField="HasCreditAmount"/>
                                            <Item />
                                            <Item dataField="CreditAmountValue" />
                                            <Item dataField="CreditAmountCurrencyId" />
                                            <Item dataField="CreditAmountExangeRate" />
                                            <Item dataField="CreditAmountCurrencyAmount" />
                                        </Item>
                                    </Form>
                                    <Popup title="Detalii linie tranzactie" showTitle={true} minWidth="40em" maxWidth="80em" height="auto" />
                                </Editing>
                                <MasterDetail
                                    enabled={true} 
                                    component={props => {
                                        return <TransactionLineTaxInformationGrid TransactionLineId={props.data.key} DefaultReportCurrencyId={this.state.DefaultReportCurrencyId} />
                                    }}
                                />
                                <Column type="buttons" width={120} minWidth="10em">
                                    <Button name="edit" cssClass="underlineBtn" />
                                    <Button name="delete" cssClass="underlineBtn" />
                                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                                    <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                                </Column>
                                <Column dataField="Id" visible={false} allowEditing={false}>
                                    <FormItem visible={false} />
                                </Column>
                                <Column dataField="RecordId" caption="ID inregistrare" dataType="string" minWidth="5em">
                                    <StringLengthRule max={18} message="ID-ul inregistrarii trebuie sa aiba maxim 18 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="AccountId" caption="Cont contabil" cssClass="vertical-left" alignment="left" minWidth="10em"
                                        calculateSortValue="Account.AccountId" editorOptions={{noDataText: "Nu exista date"}}>
                                            <Lookup
                                                dataSource={getAnalyticalAccountsDataSource()}
                                                valueExpr="Id" 
                                                displayExpr="AccountId"
                                            />
                                            <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="ValueDate" caption="Data intrarii in vigoare" 
                                    dataType="date" format="dd.MM.yyyy" minWidth="5em">
                                </Column>
                                <Column dataField="SourceDocumentId" caption="ID document sursa" dataType="string" minWidth="5em">
                                    <StringLengthRule max={35} message="ID-ul documentului sursa trebuie sa aiba maxim 35 de caractere" />
                                </Column>
                                <Column dataField="CustomerCompanyId" caption="Cumparator" cssClass="vertical-left" alignment="left" minWidth="10em" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                        dataSource={getPartenersCompanyAndCurrentCompanyMergedDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="Name"
                                    />
                                </Column>
                                <Column dataField="SupplierCompanyId" caption="Vanzator" cssClass="vertical-left" alignment="left" minWidth="10em" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                        dataSource={getPartenersCompanyAndCurrentCompanyMergedDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="Name"
                                    />
                                </Column>
                                <Column dataField="Description" caption="Descriere" dataType="string" minWidth="10em"
                                    cellRender={function (e) { return multiLineCell(e.data.Description); }} editorType="dxTextArea">
                                    <StringLengthRule max={256} message="Descrierea trebuie sa aiba maxim 256 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="HasDebitAmount" caption="Are valoare debit"
                                    visible={false} allowEditing={true} editorType="dxCheckBox"
                                    setCellValue={this.setCellValueHasDebitAmount}>
                                    <FormItem visible={true} />
                                </Column>
                                <Column dataField="DebitAmountValue" caption="Valoare suma debit" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="DebitAmount.Value" setCellValue={this.onSetCellValueDebitAmountValue}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("DebitAmount.Value", selectedFilterOperations, filterValue)}}>
                                    <StringLengthRule max={70} message="Numarul trebuie sa aiba maxim 70 de caractere" />
                                </Column>
                                <Column dataField="DebitAmountCurrencyId" caption="Moneda suma debit" minWidth="5em"
                                    calculateSortValue="DebitAmount.Currency.Code" editorOptions={{noDataText: "Nu exista date"}}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("DebitAmount.CurrencyId", selectedFilterOperations, filterValue)}}>
                                    <Lookup
                                        dataSource={getCurrenciesDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="Code"
                                    />
                                </Column>
                                <Column dataField="DebitAmountExangeRate" caption="Curs de schimb suma debit" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="DebitAmount.ExangeRate" setCellValue={this.setCellValueDebitAmountExangeRate}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("DebitAmount.ExangeRate", selectedFilterOperations, filterValue)}}>
                                </Column>
                                <Column dataField="DebitAmountCurrencyAmount" caption="Valoare in valuta suma debit" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="DebitAmount.CurrencyAmount"
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("DebitAmount.CurrencyAmount", selectedFilterOperations, filterValue)}}>
                                </Column>
                                <Column dataField="HasCreditAmount" caption="Are valoare credit"
                                    visible={false} allowEditing={true} editorType="dxCheckBox"
                                    setCellValue={this.setCellValueHasCreditAmount}>
                                    <FormItem visible={true} />
                                </Column>
                                <Column dataField="CreditAmountValue" caption="Valoare suma credit" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="CreditAmount.Value" setCellValue={this.onSetCellValueCreditAmountValue}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("CreditAmount.Value", selectedFilterOperations, filterValue)}}>
                                    <StringLengthRule max={70} message="Numarul trebuie sa aiba maxim 70 de caractere" />
                                </Column>
                                <Column dataField="CreditAmountCurrencyId" caption="Moneda suma credit" minWidth="5em"
                                    calculateSortValue="CreditAmount.Currency.Code" editorOptions={{noDataText: "Nu exista date"}}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("CreditAmount.CurrencyId", selectedFilterOperations, filterValue)}}>
                                    <Lookup
                                        dataSource={getCurrenciesDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="Code"
                                    />
                                </Column>
                                <Column dataField="CreditAmountExangeRate" caption="Curs de schimb suma credit" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="CreditAmount.ExangeRate" setCellValue={this.setCellValueCreditAmountExangeRate}
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("CreditAmount.ExangeRate", selectedFilterOperations, filterValue)}}>
                                </Column>
                                <Column dataField="CreditAmountCurrencyAmount" caption="Valoare in valuta suma credit" editorType="dxNumberBox" minWidth="5em"
                                    calculateSortValue="CreditAmount.CurrencyAmount"
                                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("CreditAmount.CurrencyAmount", selectedFilterOperations, filterValue)}}>
                                </Column>
                            </DataGrid>
                        </Item>
                    </GroupItem>
                </GeneralForm>
            </Fragment>
        )
    }
}