import React, { Component, Fragment } from 'react';
import { validatePhoneNumber, onRowUpdating, isNotEmpty, handleErrors, isAuditVisible } from "Utils/Utils"
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../../ro.json"
import notify from 'devextreme/ui/notify';
import { locale, loadMessages } from "devextreme/localization"
import  DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    SearchPanel,
    Scrolling,
    EmailRule,
    CustomRule,
    Form,
    Popup,
    FormItem,
    FilterRow,
    AsyncRule,
    Texts
} from 'devextreme-react/data-grid';
import 'components/Drawer.css'

export class UsersGrid extends Component {
    static displayName = UsersGrid.name

    constructor(props) {
        super(props)
        loadMessages(roMessages)
        locale(navigator.language)
        this.grdRef = React.createRef()
        this.state = {
            EntryId: 0,
            IsEditing: false,
            Email: null
        }
        this.setAuditEntry = (e) => {
            this.setState({ EntryId: e.row.key })
        }

        this.renderAuditPopup = (EntryId, IsEditing) => {
            if(EntryId !== 0 && EntryId !== null && EntryId){
                return <PopupComponent info={{ EntryId: EntryId, Page: "User"}} />
            }
        }

        this.emailAsyncValidation = (e) => {
            if (this.state.IsEditing){
                if (e.value === this.state.Email) {
                    return Promise.resolve(true);
                }
            }

            return fetch('/api/users/email/' + e.value)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }

        this.onEditingStart = (e) => {
            this.setState({
                EntryId: this.state.EntryId,
                IsEditing: true,
                Email: e.data.Email
            })
        }
    }

    dataSourceOptions = new CustomStore({
        key: "UserId",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if(i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`
            });

            params = params.slice(0, -1);

            return fetch(`/api/users${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/users/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        },
        insert: (values) => {
            return fetch("/api/users", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 409) {
                    notify("Adresa de e-mail este deja folosita.", "error", 3000)
                }
            })
        },
        update: (key, values) => {
            return fetch("/api/users/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if(response.status === 409) {
                    notify("Adresa de e-mail este deja folosita.", "error", 3000)
                }

                this.setState({
                    EntryId: this.state.EntryId,
                    IsEditing: false,
                    Email: null
                })
            })
        },
        remove: (key) => {
            return fetch("/api/users/" . key, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers:{
                    'Content-Type': 'application/json'
                }
            })
        }
    });

    render() {
        return (
            <Fragment>
                <DataGrid 
                    ref={this.grdRef}
                    dataSource={this.dataSourceOptions}
                    width="auto"
                    height="auto"
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    onRowUpdating={onRowUpdating}
                    id="grdUsers"
                    noDataText="Nu exista inregistrari"
                    remoteOperations={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onEditingStart={this.onEditingStart}
                >
                    <Scrolling columnRenderingMode="virtual" mode="infinite" scrollByThumb={true}/>
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <FilterRow visible={true} />
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Utilizatori" />
                        <Popup title="Utilizatori" showTitle={true} />
                        <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                    </Editing>
                    <Column type="buttons" width={120}>
                        <Button name="edit" />
                        <Button name="delete" />
                        <Button icon="info" hint="Audit" onClick={this.setAuditEntry} visile={isAuditVisible} />
                    </Column>
                    <Column dataField="UserId" visible={false} allowEditing={false} allowFiltering={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="LastName" caption="Nume">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="FirstName" caption="Prenume">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="Email" caption="Adresa de e-mail">
                        <RequiredRule message="Camp obligatoriu!" />
                        <EmailRule message="Adresa de e-mail nu este valida" />
                        <AsyncRule message="Adresa de e-mail este deja asociata unui cont" validationCallback={this.emailAsyncValidation} />
                    </Column>
                    <Column dataField="PhoneNumber" caption="Numar de telefon">
                        <CustomRule message="Numar de telefon invalid" validationCallback={validatePhoneNumber} />
                    </Column>
                </DataGrid>
                {this.renderAuditPopup(this.state.EntryId, this.state.IsEditing)}
            </Fragment>
        )
    }


}