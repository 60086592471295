// JavaScript source code
import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, RequiredRule, EmailRule, ButtonItem } from "devextreme-react/form";
import { Redirect } from 'react-router';
import notify from 'devextreme/ui/notify';
import { LoginActions } from '../../api-authorization/ApiAuthorizationConstants';

export class LoginForm extends Component {
    static displayName = LoginForm.name;

    constructor(props) {
        super(props);

        this.state = {
            redirect: false
        }
        this.formRef = React.createRef();

        this.userNameTextOptions = {
            mode: "email"
        }

        this.passwordTextOptions = {
            mode: "password"
        }

        this.submitButtonOptions = {
            text: "Login",
            width: "13em",
            onClick: (e) => {
                this.submitButtonOnClick(e);
            }
        }

        this.submitButtonOnClick = (e) => {
            var formData = this.formRef.current.instance.option("formData");
            fetch("/account", {
                method: "POST",
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 200) {
                    this.setState({ redirect: true })
                }
                else {
                    notify("Adresa de e-mail sau parola gresita!", "error", 3000)
                }
            })
        }
    }

    render() {
        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to={`/authentication/${LoginActions.Login}`} />
        }

        return (
            <Form
                className="login-form"
                ref={this.formRef}
                showColonAfterLabel={true}
                labelLocation="left"
                colCount={1}
            >
                <Item
                    dataField="Email"
                    editorType="dxTextBox"
                    editorOptions={this.userNameTextOptions}
                    label={{ text: "Adresa de e-mail" }}
                >
                    <RequiredRule message="Adresa de e-mail nu trebuie sa fie goala" />
                    <EmailRule message="Adresa de e-mail nu are o forma corecta!" />
                </Item>
                <Item
                    dataField="Password"
                    editorType="dxTextBox"
                    editorOptions={this.passwordTextOptions}
                    label={{ text: "Parola" }}
                >
                    <RequiredRule message="Parola nu trebuie sa fie goala" />
                </Item>
                <Item
                    dataField="RememberMe"
                    editorType="dxCheckBox"
                    label={{ text: "Tine-ma minte" }}
                    editorOptions={{ value: false }}
                >
                </Item>
                <ButtonItem horizontalAlignment="center" buttonOptions={this.submitButtonOptions} />


            </Form>
        )
    }
}

export default LoginForm;
