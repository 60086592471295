import { handleErrors, isNotEmpty } from "Utils/Utils";
import CustomStore from 'devextreme/data/custom_store';

const paymentsDataSource = (reportId) => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if(i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/reports/${reportId}/payments/${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/payments/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        },
        insert: (values) => {
            return fetch(`/api/reports/${reportId}/payments`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
                .then(response => {
                    return response
                })
        },
        update: (key, values) => {
            return fetch("/api/payments/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch("/api/payments/" + key, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export default paymentsDataSource;