import React, { Component, Fragment } from 'react';
import {Form as GeneralForm, Item, GroupItem } from "devextreme-react/form";
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Pager, Paging,
    Form, Popup, FormItem, StringLengthRule, Lookup, Texts
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { onRowUpdating, isAuditVisible, calculateFilterExpresion } from 'Utils/Utils';
import { getAnalyticalAccountsDataSource, getUOMsDataSource, getCurrenciesDataSource
    , getDebitCreditIndicatorsDataSource, getGoodsServicesIndicatorsDataSource, getCurrentCompanyProductsDataSource } from 'Utils/CommonDataSources';
import invoiceLineOrderReferencesDataSource from './InvoiceLineOrderReferences';
import invoiceLineTaxInformationDataSource from './InvoiceLineTaxInformation';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';

export default class InvoiceLineOrderReferencesGrid extends Component {

    constructor(props) {
        super(props)
        this.grdRef = React.createRef();
        this.nameRef = React.createRef();
        this.state = {
            InvoiceLineId: props.InvoiceLineId
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "InvoiceLineOrderReferences");
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }
    }

    render() {
        return (
                            <DataGrid
                                id="invoiceLineOrderReferencesGrid"
                                ref={this.grdRef}
                                dataSource={invoiceLineOrderReferencesDataSource(this.state.InvoiceLineId)}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                wordWrapEnabled={true}
                                columnAutoWidth={true}
                                focusedRowEnabled={false}
                                width="800px"
                                height="auto"
                                noDataText="Nu exista inregistrari"
                                remoteOperations={true}
                                onRowUpdating={onRowUpdating} 
                                onInitNewRow={this.onInitNewRow}
                            >
                                <FilterRow visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />

                                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                                    <Form colCount={1} title="Referinte comenzi" />
                                    <Popup title="Detalii referinta comanda" showTitle={true} minWidth="20em" maxWidth="40em" height="auto" />
                                    <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                                </Editing>

                                <Column type="buttons" cssClass="vertical-center" width={120}>
                                    <Button name="edit" cssClass="underlineBtn" />
                                    <Button name="delete" cssClass="underlineBtn" />
                                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                                </Column>

                                <Column dataField="Id" alignment="left" allowEditing={false} visible={false}>
                                    <FormItem visible={false} />
                                </Column>

                                <Column dataField="OriginatingOn" caption="Numar comanda" dataType="string" minWidth="5em">
                                    <StringLengthRule max={70} message="OriginatingOn trebuie sa aiba maxim 70 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>

                                <Column dataField="OrderDate" caption="Data comanda" minWidth="5em"
                                    dataType="date" format="dd.MM.yyyy">
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                            </DataGrid>
        );
    }
}