import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    componentDidMount() {
        document.body.classList.add("dx-viewport");
        //document.body.classList.add("dark-theme-navmenu");
    }

    render() {
        return (
            <div>
                <NavMenu sendPropsContainer={this.props.children}/>
            </div>
        );
    }
}
