import React, {Component} from 'react';
import { Item } from "devextreme-react/form";
import ReactDOM from 'react-dom';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { onRowUpdating, isAuditVisible, multiLineCell, calculateFilterExpresion } from "Utils/Utils";
import { getGoodsServicesIndicatorsDataSource, getUOMsDataSource, getProductCommodityCodesDataSource, getValuationMethodsDataSource } from 'Utils/CommonDataSources';
import { DataGrid } from 'devextreme-react';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    FormItem,
    Form,
    Popup,
    StringLengthRule,
    Texts,
    NumericRule,
    RangeRule,
    Lookup,
    FilterRow,
    SearchPanel,
    Paging,
    Pager,
    Scrolling,
    RemoteOperations
} from 'devextreme-react/data-grid';
import productsDataSource from './ProductsData';

export class ProductsGrid extends Component {
    constructor(props){
        super(props);
        this.grdProductsRef = React.createRef();

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Products");
        }

    }

    render() {
        return(
                <DataGrid
                    ref={this.grdPaymentsRef}
                    width="100%"
                    dataSource={productsDataSource(this.ReportId)}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    height="100%"
                    noDataText="Nu exista inregistrari"
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                    onInitNewRow={this.onInitNewRow} 
                >
                    <FilterRow visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                    <Paging defaultPageSize={10} />
                    <RemoteOperations filtering={true} />
                    <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />
                    <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                        <Popup title="Detalii produs" showTitle={true} minWidth="40em" maxWidth="80em" height="auto" />
                    </Editing>
                    <Column type="buttons" width={120}>
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible}/>
                        <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="ProductCode" caption="Cod" cssClass="vertical-left" alignment="left" minWidth="10em" dataType="string"
                        defaultSortIndex={0} defaultSortOrder="asc">
                        <RequiredRule message="Camp obligatoriu" />
                        <StringLengthRule max={70} message="Codul trebuie sa aiba maxim 70 de caractere" />
                    </Column>
                    <Column dataField="ProductNumberCode" caption="Cod numar" cssClass="vertical-left" alignment="left" minWidth="10em" dataType="string">
                        <StringLengthRule max={70} message="Codul numar trebuie sa aiba maxim 70 de caractere" />
                    </Column>
                    <Column dataField="ProductGroup" caption="Grup" cssClass="vertical-left" alignment="left" minWidth="10em" dataType="string">
                        <StringLengthRule max={70} message="Grupul trebuie sa aiba maxim 70 de caractere" />
                    </Column>
                    <Column dataField="Description" caption="Descriere" dataType="string" minWidth="10em"
                            cellRender={function (e) { return multiLineCell(e.data.Description); }} editorType="dxTextArea">
                        <StringLengthRule max={256} message="Descrierea trebuie sa aiba maxim 256 de caractere" />
                        <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="GoodsServicesId" caption="Indicator bunuri sau servicii" minWidth="5em"
                        calculateSortValue="GoodsServices.Name" editorOptions={{noDataText: "Nu exista date"}}>
                        <Lookup
                            dataSource={getGoodsServicesIndicatorsDataSource()}
                            valueExpr="Id" 
                            displayExpr="Name"
                        />
                    </Column>
                    <Column dataField="ProductCommodityCodeId" caption="Cod marfa (articol de comert)" minWidth="5em"
                        calculateSortValue="GoodsServicesIndicator.Name" editorOptions={{noDataText: "Nu exista date"}}>
                        <Lookup
                            dataSource={getProductCommodityCodesDataSource()}
                            valueExpr="Id" 
                            displayExpr="Name"
                        />
                    </Column>
                    <Column dataField="ValuationMethodId" caption="Metoda de evaluare" minWidth="5em"
                        calculateSortValue="GoodsServicesIndicator.Name" editorOptions={{noDataText: "Nu exista date"}}>
                        <Lookup
                            dataSource={getValuationMethodsDataSource()}
                            valueExpr="Id" 
                            displayExpr="Name"
                        />
                    </Column>
                    <Column dataField="UOMBaseId" caption="Unitate de masura de baza" minWidth="5em"
                        calculateSortValue="UOMBase.UnitOfMeasure" editorOptions={{noDataText: "Nu exista date"}}>
                        <Lookup
                            dataSource={getUOMsDataSource()}
                            valueExpr="Id" 
                            displayExpr="UnitOfMeasure"
                        />
                    </Column>
                    <Column dataField="UOMStandardId" caption="Unitate de masura standard" minWidth="5em"
                        calculateSortValue="UOMStandard.UnitOfMeasure" editorOptions={{noDataText: "Nu exista date"}}>
                        <Lookup
                            dataSource={getUOMsDataSource()}
                            valueExpr="Id" 
                            displayExpr="UnitOfMeasure"
                        />
                    </Column>
                    <Column dataField="UOMToUOMBaseConversionFactor" caption="Factor conversie U.M. la U.M. de baza" editorType="dxNumberBox" minWidth="5em">
                        <StringLengthRule max={20} message="Numarul trebuie sa aiba maxim 20 de caractere" />
                    </Column>
                </DataGrid>
        )
    }
}