import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { Popover } from '@material-ui/core';
import { UserMenu } from 'components/UserMenu/_UserMenu';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            fullName: null
        };

        this.refreshState = null;
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name,
            anchorElement: null
        });
        if(this.state.isAuthenticated){
            fetch("/api/users/fullname").then(response => response.json()).then(response => this.setState({fullName: response}))
        }
    }

    render() {
        const { isAuthenticated, userName, fullName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `Autentificare`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath, fullName);
        }
    }

    

    authenticatedView(userName, profilePath, logoutPath, fullName) {
        const handleClick = (event) => {
            this.setState({
                anchorElement: event.currentTarget
            })
        }

        const handleClose = () => {
            this.setState({
                anchorElement: null
            })
        }

        const open = Boolean(this.state.anchorElement)

        const id = open ? 'user-popover' : undefined;

        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} onClick={handleClick} to="#" className="nav-menu-item-custom text-light">{fullName}</NavLink>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorElement}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                >
                    <div className="user-menu-container">
                        <UserMenu />
                    </div>
                </Popover>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} to={logoutPath} className="nav-menu-item-custom text-light">Logout</NavLink>
            </NavItem>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} to={loginPath} className="nav-menu-item-custom text-light">Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
