import React, {Component, Fragment} from 'react';
import {Form as GeneralForm, Item, GroupItem } from "devextreme-react/form";
import ReactDOM from 'react-dom';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { validatePhoneNumber, onRowUpdating, isAuditVisible, calculateFilterExpresion, multiLineCell } from "Utils/Utils";
import { DataGrid } from 'devextreme-react';
import { getAnalyticalAccountsDataSource, getPartenersCompanyAndCurrentCompanyMergedDataSource, getDebitCreditIndicatorsDataSource, getCurrenciesDataSource } from 'Utils/CommonDataSources';
import paymentLinesDataSource from './PaymentLinesData';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    EmailRule,
    FormItem,
    Form,
    Popup,
    CustomRule,
    StringLengthRule,
    Texts,
    NumericRule,
    RangeRule,
    Lookup,
    FilterRow,
    SearchPanel,
    Paging,
    Pager,
    Scrolling,
    MasterDetail
} from 'devextreme-react/data-grid';
import PaymentLineTaxInformationGrid from '../PaymentLineTaxInformation/_PaymentLineTaxInformationGrid';

export class PaymentLinesGrid extends Component {
    constructor(props){
        super(props);
        this.grdPaymentsRef = React.createRef();
        this.state = {
            PaymentId: props.PaymentId,
            DefaultReportCurrencyId: props.DefaultReportCurrencyId
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "PaymentLines");
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setCellValuePaymentAmountValue = (newData, value, currentRowData) => {
            newData.PaymentLineAmountValue = value;
            newData.PaymentLineAmountCurrencyAmount = currentRowData.PaymentLineAmountExangeRate * value;
        }

        this.setCellValuePaymentAmountExangeRate = (newData, value, currentRowData) => {
            newData.PaymentLineAmountExangeRate = value;
            newData.PaymentLineAmountCurrencyAmount = currentRowData.PaymentLineAmountValue * value;
        }

        this.onInitNewRow = (e) => {
            e.data.PaymentLineAmountCurrencyId = this.state.DefaultReportCurrencyId;
            e.data.PaymentLineAmountExangeRate = 1;
            e.data.PaymentLineAmountValue = 0;
            e.data.PaymentLineAmountCurrencyAmount = 0;
        }

    }

    render() {
        return(
            <Fragment>
                <GeneralForm colCount={1}>
                    <GroupItem caption="Linii plata">
                        <Item>
                            <DataGrid
                                ref={this.grdPaymentsRef}
                                width="100%"
                                dataSource={paymentLinesDataSource(this.state.PaymentId)}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                height="100%"
                                noDataText="Nu exista inregistrari"
                                remoteOperations={true}
                                onRowUpdating={onRowUpdating}
                                onInitNewRow={this.onInitNewRow}
                            >
                                <FilterRow visible={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                                <Paging defaultPageSize={10} />
                                <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />
                                <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                                    <Form colCount={2} title="Linie plata">
                                        <Item itemType="group" colCount={2} colSpan={2}>
                                            <Item dataField="LineNumber" />
                                            <Item dataField="SourceDocumentId" />
                                            <Item dataField="AccountId" />
                                            <Item dataField="DebitCreditIndicatorId" />
                                            <Item dataField="CustomerCompanyId" />
                                            <Item dataField="SupplierCompanyId" />
                                            <Item dataField="TaxPointDate" />
                                            <Item dataField="Description" />
                                        </Item>
                                        <Item itemType="group" caption="Valoare" colCount={2} colSpan={2}>
                                            <Item dataField="PaymentLineAmountValue" />
                                            <Item dataField="PaymentLineAmountCurrencyId" />
                                            <Item dataField="PaymentLineAmountExangeRate" />
                                            <Item dataField="PaymentLineAmountCurrencyAmount" />
                                        </Item>
                                    </Form>
                                    <Popup title="Detalii linie plata" showTitle={true} minWidth="40em" maxWidth="80em" height="auto" />
                                </Editing>
                                <MasterDetail
                                    enabled={true} 
                                    component={props => {
                                        return <PaymentLineTaxInformationGrid PaymentLineId={props.data.key} DefaultReportCurrencyId={this.state.DefaultReportCurrencyId} />
                                    }}
                                />
                                <Column type="buttons" width={120}>
                                    <Button name="edit" cssClass="underlineBtn" />
                                    <Button name="delete" cssClass="underlineBtn" />
                                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                                    <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                                </Column>
                                <Column dataField="Id" visible={false} allowEditing={false}>
                                    <FormItem visible={false} />
                                </Column>
                                <Column dataField="LineNumber" caption="Numar linie" dataType="string" minWidth="5em">
                                    <StringLengthRule max={18} message="Numarul de referinta trebuie sa aiba maxim 18 de caractere" />
                                </Column>
                                <Column dataField="SourceDocumentId" caption="ID document sursa" dataType="string" minWidth="5em">
                                    <StringLengthRule max={35} message="ID-ul documentului sursa trebuie sa aiba maxim 35 de caractere" />
                                </Column>
                                <Column dataField="AccountId" caption="Cont contabil" cssClass="vertical-left" alignment="left" minWidth="10em"
                                        calculateSortValue="Account.AccountId" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                        dataSource={getAnalyticalAccountsDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="AccountId"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="DebitCreditIndicatorId" caption="Indicator debit credit" minWidth="5em"
                                        calculateSortValue="DebitCreditIndicator.Name" editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                        dataSource={getDebitCreditIndicatorsDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="Name"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="CustomerCompanyId" caption="Cumparator" cssClass="vertical-left" alignment="left" minWidth="10em"
                                    editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                        dataSource={getPartenersCompanyAndCurrentCompanyMergedDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="Name"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="SupplierCompanyId" caption="Vanzator" cssClass="vertical-left" alignment="left" minWidth="10em"
                                    editorOptions={{noDataText: "Nu exista date"}}>
                                    <Lookup
                                        dataSource={getPartenersCompanyAndCurrentCompanyMergedDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="Name"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="TaxPointDate" caption="Data exigibilitate/ data factura" dataType="date" 
                                    format="dd.MM.yyyy" minWidth="5em">
                                </Column>
                                <Column dataField="Description" caption="Descriere" dataType="string" minWidth="10em"
                                        cellRender={function (e) { return multiLineCell(e.data.Description); }} editorType="dxTextArea">
                                        <StringLengthRule max={256} message="Descrierea trebuie sa aiba maxim 256 de caractere" />
                                        <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="PaymentLineAmountValue" caption="Valoare" editorType="dxNumberBox" minWidth="5em"
                                        calculateSortValue="PaymentLineAmount.Value" setCellValue={this.setCellValuePaymentAmountValue}
                                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("PaymentLineAmount.Value", selectedFilterOperations, filterValue)}}>
                                    <StringLengthRule max={70} message="Numarul trebuie sa aiba maxim 70 de caractere" />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="PaymentLineAmountCurrencyId" caption="Moneda" minWidth="5em"
                                        calculateSortValue="PaymentLineAmount.Currency.Code" editorOptions={{noDataText: "Nu exista date"}}
                                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("PaymentLineAmount.CurrencyId", selectedFilterOperations, filterValue)}}>
                                    <Lookup
                                        dataSource={getCurrenciesDataSource()}
                                        valueExpr="Id" 
                                        displayExpr="Code"
                                    />
                                    <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="PaymentLineAmountExangeRate" caption="Curs de schimb" editorType="dxNumberBox" minWidth="5em"
                                        calculateSortValue="PaymentLineAmount.ExangeRate" setCellValue={this.setCellValuePaymentAmountExangeRate}
                                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("PaymentLineAmount.ExangeRate", selectedFilterOperations, filterValue)}}>
                                            <RequiredRule message="Camp obligatoriu" />
                                </Column>
                                <Column dataField="PaymentLineAmountCurrencyAmount" caption="Valoare in valuta" editorType="dxNumberBox" minWidth="5em"
                                        calculateSortValue="PaymentLineAmount.CurrencyAmount"
                                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("PaymentLineAmount.CurrencyAmount", selectedFilterOperations, filterValue)}}>
                                            <RequiredRule message="Camp obligatoriu" />
                                </Column>
                            </DataGrid>
                        </Item>
                    </GroupItem>
                </GeneralForm>
            </Fragment>
        )
    }
}