import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { onRowUpdating, isAuditVisible, multiLineCell } from "Utils/Utils";
import { DataGrid } from 'devextreme-react';
import journalsDataSource from './JournalsData';
import { TransactionsGrid } from '../Transactions/_TransactionsGrid';
import { getReportDefaultCurrencyIdAsync } from 'Utils/CommonDataSources';
//import PurchaseInvoiceMasterDetail from './MasterDetail/_PurchaseInvoiceMasterDetail';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    EmailRule,
    FormItem,
    Form,
    Popup,
    CustomRule,
    StringLengthRule,
    Texts,
    Lookup,
    FilterRow,
    Scrolling,
    MasterDetail,
    NumericRule,
    RangeRule,
    SearchPanel,
    Paging,
    Pager
} from 'devextreme-react/data-grid';

export class JournalsGrid extends Component {
    constructor(props){
        super(props);
        this.grdJournalsRef = React.createRef();

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "GeneralLedgerJournals");
        }

        this.ReportId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

        this.DefaultReportCurrencyId = null;

        this.onInitNewRow = (e) => {
            e.data.SelfBillingIndicatorId = 1;
        }
    }

    render() {
        return(
                <DataGrid
                    ref={this.grdJournalsRef}
                    dataSource={journalsDataSource(this.ReportId)}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    height="100%"
                    width="auto"
                    noDataText="Nu exista inregistrari"
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                    onInitNewRow={this.onInitNewRow}
                >
                    <FilterRow visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />
                    <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Jurnale de tranzactii" />
                        <Popup title="Detalii jurnal" showTitle={true} minWidth="20em" maxWidth="40em" height="360px" />
                    </Editing>
                    <MasterDetail
                            enabled={true} 
                            component={props => {
                                return <TransactionsGrid JournalId={props.data.key} DefaultReportCurrencyId={this.DefaultReportCurrencyId} />
                            }}
                    />
                    <Column type="buttons" width={120} minWidth="10em" fixed={true} fixedPosition="left">
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="JournalId" caption="ID jurnal" dataType="string" minWidth="5em" fixed={true} fixedPosition="left">
                        <StringLengthRule max={18} message="Identificatorul jurnalului trebuie sa aiba maxim 18 de caractere" />
                        <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="Description" caption="Descriere" dataType="string" minWidth="5em" fixed={true} fixedPosition="left"
                        cellRender={function (e) { return multiLineCell(e.data.Description); }} editorType="dxTextArea">
                        <StringLengthRule max={256} message="Descrierea trebuie sa aiba maxim 256 de caractere" />
                        <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="Type" caption="Tip" dataType="string" minWidth="5em" fixed={true} fixedPosition="left">
                        <StringLengthRule max={9} message="Tipul trebuie sa aiba maxim 9 de caractere" />
                        <RequiredRule message="Camp obligatoriu" />
                    </Column>
                </DataGrid>
        )
    }

    async componentDidMount() {  
        try {
            this.DefaultReportCurrencyId = await getReportDefaultCurrencyIdAsync(this.ReportId);
        } catch(err) {}
    
        //b(); // after function a finished, this function will calls
    }
}