import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import { Item } from "devextreme-react/form";
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { onRowUpdating, isAuditVisible } from "Utils/Utils";
import { DataGrid } from 'devextreme-react';
import { companyBankAccountsDataSource } from '../_CompanyMasterDetailDataSources';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    EmailRule,
    FormItem,
    Form,
    Popup,
    StringLengthRule,
    PatternRule,
    Texts
} from 'devextreme-react/data-grid';

export class CompanyBankAccountsGrid extends Component {
    constructor(props){
        super(props);
        this.grdBankAccountsRef = React.createRef();
        this.state = {
            CompanyId: props.CompanyId
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "CompanyBankAccounts");
        }

        this.onRowValidating = (e) => {
            if(e.oldData == undefined){
                if((typeof e.newData.IBANNumber != undefined && e.newData.IBANNumber == null) && 
                    ((e.newData.BankAccountNumber != undefined && e.newData.BankAccountNumber == null) 
                        || (typeof e.newData.BankAccountName != undefined && e.newData.BankAccountName == null)
                        || (typeof e.newData.SortCode != undefined && e.newData.SortCode == null)))
                {
                    e.errorText = "Daca nu ati completat IBAN-ul, atunci trebuie sa completati celelalte 3 campuri";
                    e.isValid = false;
                }
            }
            else{
                var currentIBANNumber = e.newData.IBANNumber != undefined ? e.newData.IBANNumber : e.oldData.IBANNumber;
                var currentBankAccountNumber = e.newData.BankAccountNumber != undefined ? e.newData.BankAccountNumber : e.oldData.BankAccountNumber;
                var currentBankAccountName = e.newData.BankAccountName != undefined ? e.newData.BankAccountName : e.oldData.BankAccountName;
                var currentSortCode = e.newData.SortCode != undefined ? e.newData.SortCode : e.oldData.SortCode;
                if(!currentIBANNumber && (!currentBankAccountNumber || !currentBankAccountName || !currentSortCode)){
                    e.errorText = "Daca nu ati completat IBAN-ul, atunci trebuie sa completati celelalte 3 campuri";
                    e.isValid = false;
                }
            }
        }
    }

    render() {
        return(
            <div className="container-master-detail">
                <DataGrid
                    ref={this.grdBankAccountsRef}
                    width="100%"
                    dataSource={companyBankAccountsDataSource(this.state.CompanyId)}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    height="100%"
                    noDataText="Nu exista inregistrari"
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating} 
                    onRowValidating={this.onRowValidating}
                >
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Cont bancar">
                            <label>test</label>
                            <Item itemType="group" caption="IBAN" colCount={1} colSpan={1}>
                                <Item dataField="IBANNumber" />
                            </Item>
                            <Item itemType="group" caption="Sau detalii cont bancar" colCount={1} colSpan={1}>
                                <Item dataField="BankAccountNumber"/>
                                <Item dataField="BankAccountName" />
                                <Item dataField="SortCode" />
                            </Item>
                        </Form>
                        <Popup title="Detalii cont bancar" showTitle={true} minWidth="20em" maxWidth="40em" height="auto" />
                    </Editing>
                    <Column type="buttons" width={120}>
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="IBANNumber" caption="IBAN" defaultSortIndex={0} defaultSortOrder="asc">
                        <StringLengthRule max={35} message="IBAN-ul trebuie sa aiba maxim 35 de caractere" />
                        <PatternRule message="IBAN-ul nu are un format valid!" pattern="^([a-zA-Z]{2}[0-9]*)$" />
                    </Column>
                    <Column dataField="BankAccountNumber" caption="Numar cont bancar">
                        <StringLengthRule max={35} message="Numarul contului bancar trebuie sa aiba maxim 35 de caractere" />
                    </Column>
                    <Column dataField="BankAccountName" caption="Nume cont bancar">
                        <StringLengthRule max={70} message="Numele contului bancar trebuie sa aiba maxim 70 de caractere" />
                    </Column>
                    <Column dataField="SortCode" caption="Cod de sortare">
                        <StringLengthRule max={18} message="Codul de sortare trebuie sa aiba maxim 18 de caractere" />
                    </Column>
                </DataGrid>
            </div>
        )
    }
}