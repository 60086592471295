import React, { Component } from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import SaleInvoices from "./Documents/SaleInvoices/_SaleInvoices";
import PurchaseInvoices from "./Documents/PurchaseInvoices/_PurchaseInvoices";
import Payments from "./Documents/Payments/_Payments";
import Journals from "./Documents/GeneralLedgerEntries/Journals/_Journals";
import GeneralLedgerAccountsBalances from "./Documents/GeneralLedgerAccountsBalances/_GeneralLedgerAccountsBalances";
import PartnersBalances from "./Documents/PartnersBalances/_PartnersBalances";

export class ReportDetailsDocuments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            DocumentTypeNumber: null
        }
      }



    render() {
        if(this.state.DocumentTypeNumber === 0){
            return (
                <Journals />
            )
        }
        else if(this.state.DocumentTypeNumber === 1){
            return (
                <SaleInvoices />
            )
        }
        else if(this.state.DocumentTypeNumber === 2){
            return (
                <PurchaseInvoices />
            )
        }
        else if(this.state.DocumentTypeNumber === 3){
            return (
                <Payments />
            )
        }
        else if(this.state.DocumentTypeNumber === 4){
            return (
                <GeneralLedgerAccountsBalances />
            )
        }
        else if(this.state.DocumentTypeNumber === 5){
            return (
                <PartnersBalances />
            )
        }else{
            return (
                <div></div>
            )
        }
    }

    componentDidMount(){
        this.setState({
            DocumentTypeNumber: this.props.DocumentTypeNumber
        })
    }

    componentDidUpdate(prevProps){
        const {DocumentTypeNumber} = prevProps;
        if(this.props.DocumentTypeNumber !== DocumentTypeNumber){
            this.setState({
                DocumentTypeNumber: this.props.DocumentTypeNumber
            })
        }
    }
}

export default ReportDetailsDocuments;