import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Pager, Paging,
    Form, Popup, MasterDetail,FormItem, StringLengthRule, Toolbar, Item, Texts
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'custom.css';
import { onRowUpdating, isAuditVisible, getCurrentCompanyFromLocalStorage } from 'Utils/Utils';
import dataSourceOptions  from "../../Partners/PartnersData";
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { AddPopup } from "./Forms/_AddPopup";
import { EditPopup } from './Forms/_EditPopup';
import { CompanyMasterDetail } from 'components/Shared/CompanyMasterDetail/_CompanyMasterDetail';
import notify from 'devextreme/ui/notify';

export default class PartnersGrid extends Component {
    static displayName = PartnersGrid.name;

    constructor(props) {
        super(props)
        this.grdRef = React.createRef();
        this.nameRef = React.createRef();

        this.toolbarCustomText = getCurrentCompanyFromLocalStorage() ? "" : "Trebuie selectata compania curenta";
        this.displayWarningText = getCurrentCompanyFromLocalStorage() ? "none" : "";

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "CompanyPartners");
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, 
                document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.renderAddPopup = () => {
            if(getCurrentCompanyFromLocalStorage()){
                const component = ReactDOM.render(
                    <AddPopup grdRef={this.grdRef}/>,
                    document.getElementById("addPopupContainer")
                );
                component.forceUpdate();
            }
            else
            {
                notify("Trebuie selectata compania curenta pentru a putea adauga parteneri acesteia");
            }
        }

        this.renderEditPopup = (e) => {
            const component = ReactDOM.render(
                <EditPopup grdRef={this.grdRef} companyId={e.row.key} companyObject={e.row.data} />,
                document.getElementById("editPopupContainer")
            );
            component.forceUpdate();
        }
    }

    render() {
        return (
            <div>
                <div className="danger-text" style={{display: this.displayWarningText}}>
                    <label>{this.toolbarCustomText}</label>
                </div>
                <div className="container-datagrid">
                    <DataGrid
                        id="partnersGrid"
                        ref={this.grdRef}
                        dataSource={dataSourceOptions()}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        width="auto"
                        height="auto" 
                        noDataText="Nu exista inregistrari"
                        remoteOperations={true}
                        onRowUpdating={onRowUpdating}
                        onToolbarPreparing={(e) => {
                            e.toolbarOptions.items.unshift({
                                location: "after",
                                widget: "dxButton",
                                options: {
                                    icon: "add",
                                    onClick: (e) => {
                                        this.renderAddPopup();
                                    }
                                }
                            })
                        }}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />

                        <Editing allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                            <Form colCount={1} title="Partener" />
                            <Popup title="Detalii partener" showTitle={true} minWidth="20em" maxWidth="40em" height="auto" />
                            <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                        </Editing>

                        <MasterDetail
                            enabled={true} 
                            component={props => {
                                return <CompanyMasterDetail CompanyId={props.data.data.CompanyId} />
                            }}
                        />

                        <Column type="buttons" cssClass="vertical-center" width={120}>
                            <Button icon="edit" cssClass="underlineBtn" onClick={this.renderEditPopup} />
                            <Button name="delete" cssClass="underlineBtn" />
                            <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        </Column>

                        <Column dataField="Id" visible={false} allowEditing={false} allowFiltering={false}>
                            <FormItem visible={false} />
                        </Column>
                        <Column dataField="Name" caption="Nume companie partener" cssClass="vertical-left" alignment="left" minWidth="10em" dataType="string"
                            defaultSortIndex={0} defaultSortOrder="asc" calculateSortValue="Company.Name">
                            <RequiredRule message="Name must not be ampty!" />
                            <StringLengthRule max={70} message="Numele trebuie sa aiba maxim 70 de caractere" />
                        </Column>
                        <Column dataField="RegistrationNumber" caption="CUI" cssClass="vertical-left" alignment="left" minWidth="10em" dataType="string"
                            calculateSortValue="Company.RegistrationNumber">
                            <StringLengthRule max={35} message="CUI-ul trebuie sa aiba maxim 35 de caractere" />
                        </Column>
                        <Column dataField="PartnerIdentifier" caption="Identificator" cssClass="vertical-left" alignment="left" minWidth="10em" dataType="string">
                            <StringLengthRule max={35} message="Identificator trebuie sa aiba maxim 35 de caractere" />
                        </Column>
                        <Column dataField="CompanyId" visible={false} allowEditing={false} allowFiltering={false}>
                            <FormItem visible={false} />
                        </Column>
                    </DataGrid>
                </div>
            </div>
        );
    }
}