import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Redirect } from 'react-router';
const qs = require('qs')

export class ConfirmAccount extends Component {
    static displayName = ConfirmAccount.name;

    constructor(props) {
        super(props)
        this.state = {
            redirect: false
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/Autentificare' />
        }
        else {
            return (
                <div />
            )
        }
    }

    componentDidMount() {
        const userId = this.props.match.params.userId;
        const token = qs.parse(this.props.location.search)["?token"]
        if (token && userId) {
            return fetch(`/api/users`, {
                method: "PUT",
                body: JSON.stringify({
                    UserId: userId,
                    Token: token
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 200) {
                    this.setState({
                        redirect: true
                    })
                }
            })
        }
    }
}

export default ConfirmAccount;