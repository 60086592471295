import CustomStore from 'devextreme/data/custom_store';
import { isNotEmpty, handleErrors, getCurrentCompanyFromLocalStorage } from "Utils/Utils";

export function getCompaniesDataSource(schedulerRef) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companies${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    if(response.data.length > 0 && !schedulerRef.current.instance.value){
                        if(getCurrentCompanyFromLocalStorage()){
                            schedulerRef.current.instance.option("value", getCurrentCompanyFromLocalStorage())
                        }
                        else{
                            schedulerRef.current.instance.option("value", response.data[0].Id);
                        }
                    }
                    return response;
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch(`/api/companies/${key}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }
        
    })
}

function setSelectedCompany(companyId){
    localStorage.setItem("CurrentCompany", companyId.toString())
}