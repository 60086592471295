import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import Form, { Item, RequiredRule, EmailRule, ButtonItem } from "devextreme-react/form";
import notify from 'devextreme/ui/notify';

export class ForgotPassword extends Component {
    static displayName = ForgotPassword.name;

    constructor(props) {
        super(props);

        this.passwordRef = React.createRef();

        this.state = {
            visible: false
        }

        this.hidePopup = (e) => {
            this.setState({
                visible: false
            })
        }

        this.forgotPasswordButtonOnClick = (e) => {
            this.setState({
                visible: true
            })
        }

        this.sendPasswordReset = (e) => {
            var email = this.passwordRef.current.instance.option("formData");
            fetch("/account/forgot-password", {
                method: "POST",
                body: JSON.stringify(email),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.status === 200) {
                    this.hidePopup();
                    notify("A fost trimis un e-mail la adresa specificata. Va rugam sa urmati instructiunile din interior pentru a va schimba parola.", "success", 6000)
                }
                else {
                    notify("Aceasta adresa de e-mail nu are un cont asociat.", "error", 3000)
                }
            })
        }

        this.forgotPasswordSubmitOptions = {
            onClick: (e) => { this.sendPasswordReset(e) },
            text: "Resetare parola"
        }
    }

    render() {
        return (
            <div className="popup-wrapper">
                <Button text="Am uitat parola" onClick={this.forgotPasswordButtonOnClick} className="login-buttons" />
                <Popup
                    visible={this.state.visible}
                    onHiding={this.hidePopup}
                    showTitle={true}
                    title="Am uitat parola"
                    height="auto"
                    minWidth="50vw"
                    maxWidth="80vw"
                >
                    <Form
                        ref={this.passwordRef}
                        showColonAfterLabel={true}
                        labelLocation="left"
                        colCount={1}
                    >
                        <Item
                            dataField="Email"
                            editorType="dxTextBox"
                            label={{ text: "Adresa de e-mail" }}
                            width="100em"
                        >
                            <RequiredRule message="Adresa de e-mail nu trebuie sa fie goala!" />
                            <EmailRule message="Adresa de e-mail nu are un format corect!" />
                        </Item>
                        <ButtonItem horizontalAlignment="center" buttonOptions={this.forgotPasswordSubmitOptions} />
                    </Form>
                </Popup>
            </div>
        )
    }
}