import React, { Component } from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, ScrollView } from 'devextreme-react';
import { ButtonItem, EmptyItem, Item, RequiredRule, Label, StringLengthRule } from "devextreme-react/form";
import { handleErrors } from 'Utils/Utils';
import dxTextBox from 'devextreme/ui/text_box';
import { PatternRule } from 'devextreme-react/data-grid';

export class EditPopup extends Component{
    constructor(props){
        super(props)
        this.popupRef = React.createRef();
        this.formRef = React.createRef();
        this.state = {
            grdRef: null,
            companyId: 0,
            companyObject: null
        }

        this.setData = false;

        this.updateButtonOptions = () => {
            return {
                text: "Actualizeaza",
                onClick: (e) => {
                    var instance = this.formRef.current.instance;
                    if(!instance.validate().isValid) {
                        return;
                    }

                    var formData = instance.option("formData")
                    return fetch("/api/companypartners/" + this.props.companyId, {
                        method: "PUT",
                        body: JSON.stringify(formData),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(response => response.json())
                    .then(response => {
                        this.formRef.current.instance.resetValues();
                        this.state.grdRef.current.instance.refresh();
                        this.popupRef.current.instance.hide();
                    })
                }
            }
        }

        this.setFormData = (companyObject) => {
            this.setData = true;
            var form = this.formRef.current.instance;
            form.getEditor("RegistrationNumber").option("value", companyObject.RegistrationNumber);
            form.getEditor("Name").option("value", companyObject.Name);
            form.getEditor("PartnerIdentifier").option("value", companyObject.PartnerIdentifier);
            this.setData = false;
        }
    }

    render() {
        return (
            <div>
                <Popup
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    ref={this.popupRef}
                    title="Detalii partener"
                    minWidth="20em"
                    maxWidth="40em"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <Form
                            ref={this.formRef}
                            showColonAfterLabel={true}
                            labelLocation="left"
                            colCount={1}
                        >
                            <Item
                                dataField="Name"
                                caption="Nume companie">
                                <Label text="Nume companie" />
                                <RequiredRule message="Numele companiei nu poate fi gol!" />
                                <StringLengthRule max={70} message="Numele trebuie sa aiba maxim 70 de caractere" />
                            </Item>
                            <Item
                                    dataField="RegistrationNumber" 
                                    caption="CUI"
                                    editorType={dxTextBox}>
                                    <Label text="CUI" />
                                    <RequiredRule message="CUI-ul nu trebuie sa fie gol!" />
                                    {/* <PatternRule message="CUI-ul nu are un format valid!" pattern="^([0-9]{1,9}[0-9])$" /> */}
                                    <StringLengthRule max={35} message="CUI-ul trebuie sa aiba maxim 35 de caractere" />
                            </Item>
                            <Item
                                    dataField="PartnerIdentifier" 
                                    caption="Identificator"
                                    editorType={dxTextBox}>
                                    <Label text="Identificator" />
                                    <RequiredRule message="Identificator nu trebuie sa fie gol!" />
                                    <StringLengthRule max={35} message="Identificator trebuie sa aiba maxim 35 de caractere" />
                                </Item>
                            <EmptyItem />
                            <ButtonItem 
                                horizontalAlignment="right"
                                buttonOptions={this.updateButtonOptions()}/>
                        </Form>
                    </ScrollView>
                </Popup>
            </div>
        )
    }

    componentDidMount(props) {
        const { grdRef, companyId, companyObject } = this.props
        this.setState({
            grdRef: grdRef,
            companyId: companyId,
            companyObject: companyObject
        })
        this.popupRef.current.instance.show();
        this.setFormData(companyObject);
    }

    componentDidUpdate(prevProps){
        const { grdRef, companyId, companyObject } = prevProps;
        if(grdRef !== this.props.grdRef || companyId !== this.props.companyId){
            this.setState({
                grdRef: grdRef,
                companyId: companyId,
                companyObject: companyObject
            })
        }
        this.popupRef.current.instance.show();
        if(!this.setData){
            if(this.formRef.current !== null){
                this.setFormData(companyObject);
            }
        }
    }
}