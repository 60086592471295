import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { onRowUpdating, isAuditVisible, calculateFilterExpresion } from "Utils/Utils";
import { CompanyAddressGridAddPopup } from "components/Shared/CompanyMasterDetail/CompanyMasterDetailDataGrids/Forms/_AddPopupCompanyAddressesGrid";
import { CompanyAddressGridEditPopup } from "components/Shared/CompanyMasterDetail/CompanyMasterDetailDataGrids/Forms/_EditPopupCompanyAddressesGrid";
import { DataGrid } from 'devextreme-react';
import { companyAddressesDataSource } from '../_CompanyMasterDetailDataSources';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    EmailRule,
    StringLengthRule, Texts
} from 'devextreme-react/data-grid';

export class CompanyAddressesGrid extends Component {

    constructor(props){
        super(props);
        this.grdAddressesRef = React.createRef();
        this.state = {
            CompanyId: props.CompanyId
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "CompanyAddresses");
        }

        this.renderAddPopup = () => {
            const component = ReactDOM.render(
                <CompanyAddressGridAddPopup grdRef={this.grdAddressesRef} companyId={this.state.CompanyId} />,
                document.getElementById("addPopupContainerCompanyAddresses")
            );
            component.forceUpdate();
        }

        this.renderEditPopup = (e) => {
            const component = ReactDOM.render(
                <CompanyAddressGridEditPopup grdRef={this.grdAddressesRef} companyId={this.state.CompanyId} 
                    addressId={e.row.key} addressObject={e.row.data} />,
                document.getElementById("editPopupContainerCompanyAddresses")
            );
            component.forceUpdate();
        }

    }

    render() {
        return(
            <div className="container-master-detail">
                <DataGrid
                    ref={this.grdAddressesRef}
                    width="100%"
                    dataSource={companyAddressesDataSource(this.state.CompanyId)}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    height="100%"
                    noDataText="Nu exista inregistrari"
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                    onToolbarPreparing={(e) => {
                        e.toolbarOptions.items.unshift({
                            location: "after",
                            widget: "dxButton",
                            options: {
                                icon: "add",
                                onClick: (e) => {
                                    this.renderAddPopup();
                                }
                            }
                        })
                    }}
                >
                    <Editing allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true} />
                    <Column type="buttons" width={120}>
                        <Button name="edit" cssClass="underlineBtn" onClick={this.renderEditPopup} />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false} />
                    <Column dataField="CountryName" caption="Tara" cssClass="vertical-center" alignment="center" minWidth="10em"
                            calculateSortValue="Country.Name" defaultSortIndex={0} defaultSortOrder="asc"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Country.Name", selectedFilterOperations, filterValue)}}
                        >
                            <RequiredRule message="Tara trebuie selectata!" />
                    </Column>
                    <Column dataField="RegionName" caption="Regiune" cssClass="vertical-center" alignment="center" minWidth="10em"
                            calculateSortValue="Region.Name" defaultSortIndex={1} defaultSortOrder="asc"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Region.Name", selectedFilterOperations, filterValue)}}
                        >
                    </Column>
                    <Column dataField="CityName" caption="Oras" cssClass="vertical-center" alignment="center" minWidth="10em"
                            calculateSortValue="City.Name" defaultSortIndex={2} defaultSortOrder="asc"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("City.Name", selectedFilterOperations, filterValue)}}
                        >
                            <RequiredRule message="Orasul trebuie selectat!" />
                    </Column>
                    <Column dataField="PostalCode" caption="Cod postal">
                        <RequiredRule message="Camp obligatoriu" />
                        <StringLengthRule max={18} message="Codul postal trebuie sa aiba maxim 18 de caractere" />
                    </Column>
                    <Column dataField="StreetName" caption="Strada">
                        <StringLengthRule max={70} message="Strada trebuie sa aiba maxim 70 de caractere" />
                    </Column>
                    <Column dataField="Number" caption="Numar">
                        <StringLengthRule max={18} message="Numarul trebuie sa aiba maxim 18 de caractere" />
                    </Column>
                    <Column dataField="AdditionalAddressDetail" caption="Detalii">
                        <StringLengthRule max={70} message="Detaliile trebuie sa aiba maxim 70 de caractere" />
                    </Column>
                    <Column dataField="Building" caption="Cladire">
                        <StringLengthRule max={35} message="Cladirea trebuie sa aiba maxim 35 de caractere" />
                    </Column>
                    <Column dataField="RomanianAddressTypeName" caption="Tip adresa" cssClass="vertical-center" alignment="center" minWidth="10em"
                            calculateSortValue="AddressType.RomanianName"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("AddressType.RomanianName", selectedFilterOperations, filterValue)}}
                        >
                    </Column>
                </DataGrid>
            </div>
        )
    }

}