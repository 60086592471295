import React, { Component, Fragment } from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import RadioGroup from 'devextreme-react/radio-group';
import ReportDetailsDocuments from "./_ReportDetailsDocuments";
import { ApplicationPaths } from "components/api-authorization/ApiAuthorizationConstants";
import { APP_REPORT_INITIAL_STATUS_ID } from "constantAppValues";

export class ReportDetails extends Component {

    constructor(props) {
        super(props);
        this.reportDocuments = [
            {text: 'Tranzactii contabile', value: 0}, 
            {text: 'Facturi de vanzare', value: 1}, 
            {text: 'Facturi de achizitie', value: 2}, 
            {text: 'Plati', value: 3},
            {text: 'Solduri conturi', value: 4},
            {text: 'Solduri parteneri', value: 5}
        ];

        this.state = {
            DocumentTypeNumber: 0
        }

        var reportId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

        fetch('/api/reports/' + reportId + '/statusId')
        .then(response => response.json())
        .then(response => {
            if(response != APP_REPORT_INITIAL_STATUS_ID){
                this.redirectToReportsPage();
            }
        })
        .catch(err => {
            this.redirectToReportsPage();
        });

        fetch('/api/reports/' + reportId + '/default-currency-id')
        .then(response => {
            if (response.status == 200){
                return response.json();
            }else{
                this.redirectToReportsPage();
            }
        })
        .catch(err => {
            this.redirectToReportsPage();
        });

        this.redirectToReportsPage = () => {
            const redirectUrl = `${window.location.origin}${ApplicationPaths.ReportsPath}`;
            window.location.replace(redirectUrl);
        }

    }

    render() {
        return (
            <Fragment>
                <div style={{display: "flex"}}>
                        <div style={{whiteSpace: "nowrap", width: "12%"}}>
                            <RadioGroup dataSource={this.reportDocuments} defaultValue={this.reportDocuments[0]} onValueChanged={(e) => this.setState({DocumentTypeNumber: e.value.value})} />
                        </div>
                        <div style={{width: "88%"}}>
                            <ReportDetailsDocuments DocumentTypeNumber={this.state.DocumentTypeNumber} />
                        </div>
                </div>
            </Fragment>
        )
    }
}

export default ReportDetails;