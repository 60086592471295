import React from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
    Editing,
    Column,
    SearchPanel,
    Scrolling,
    FilterRow
} from 'devextreme-react/data-grid';
import { handleErrors, isNotEmpty } from 'Utils/Utils';
import { TextArea } from 'devextreme-react/text-area';

const AuditGrid = (props) => {
    const dataSourceOptions = new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);
            return fetch(`/AuditEntries/${props.Page}/${props.EntryId}${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        }
    })

    return (
        <DataGrid
            width="100%"
            dataSource={dataSourceOptions}
            showBorders={true}
            showColumnLines={true}
            showRowLines={true}
            wordWrapEnabled={true}
        >
            <FilterRow visible={true} />
            <SearchPanel visible={true} />
            <Scrolling columnRenderingMode="virtual" mode="infinite" />
            <Editing allowAdding={false} allowUpdating={false} allowDeleting={false} />
            <Column dataField="ModificationTime" caption="Data modificare" dataType="datetime" format="dd.MM.yyyy HH:mm:ss" alignment="center" cssClass="vertical-center" width="15%" />
            <Column dataField="UserName" caption="Utilizator" alignment="center" cssClass="vertical-center" width="25%"/>
            <Column dataField="Operation" caption="Operatie" alignment="center" cssClass="vertical-center" width="15%"/>
            <Column dataField="Modification" caption="Modificari" cellRender={renderTextArea} />
        </DataGrid>
    )
}

function renderTextArea(data) {
    return <TextArea
        value={data.value}
        height="5em"
        readOnly={true}
    />
}

export default AuditGrid;