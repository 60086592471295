import React, { Component, Fragment } from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, GroupItem } from "devextreme-react/form";
import PartnersGrid from 'components/Partners/PartnersGrid/_PartnersGrid';

export class Partners extends Component {
    static displayName = Partners.name;

    render() {
        return (

            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Parteneri">
                        <Item>
                            <PartnersGrid />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="addPopupContainer" />
                <div id="editPopupContainer" />
                <div id="auditPopupContainer" />
                <div id="addPopupContainerCompanyAddresses" />
                <div id="editPopupContainerCompanyAddresses" />
            </Fragment>
        )
    }
}

export default Partners;