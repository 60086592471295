import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Redirect } from 'react-router';
import { ForgotPassword } from './ForgotPassword/_ForgotPassword';



export class SetPassword extends Component {
    static displayName = SetPassword.name;

    constructor(props) {
        super(props);
        this.state = {
            id: null,
            token: null
        }
    }

    render() {
        if (this.state.id != null) {
            switch (this.state.id) {
                case "1":
                    return (
                        <div className="row my-auto h-100">
                            <div className="col-sm-1 col-md-2 col-lg-4 my-auto" />
                            <div className="col-sm-10 col-md-8 col-lg-4 my-auto login-container">
                                <ForgotPassword token={this.state.token} />
                            </div>
                            <div className="col-sm-1 col-md-2 col-lg-4 my-auto" />
                        </div>
                    )
                case "2":
                    return (
                        <h1>Schimb parola</h1>
                    )
                default:
                    return <Redirect to='/' />

            }
        }

        return (
            <div />
        )
    }

    componentDidMount() {
        this.setState({
            id: this.props.match.params.id,
            token: this.props.match.params.token
        })
    }
}

export default SetPassword
