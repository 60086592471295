import React, { Component, Fragment } from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Form, { Item, GroupItem } from "devextreme-react/form";
import { SaleInvoicesGrid } from "./_SaleInvoicesGrid";

export class SaleInvoices extends Component {
    static displayName = SaleInvoices.name;

    render() {
        return (
            <div>
                <Form colCount={1}>
                    <GroupItem caption="Facturi de vanzare">
                        <Item>
                            <SaleInvoicesGrid />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
            </div>
        )
    }
}

export default SaleInvoices;