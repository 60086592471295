import React, { Component, Fragment } from 'react';
//import {Form as GeneralForm, Item, GroupItem } from "devextreme-react/form";
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import ReactDOM from 'react-dom';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow, RequiredRule,
    SearchPanel,
    Pager, Paging,
    Form, Popup, FormItem, StringLengthRule, Lookup, Texts
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { onRowUpdating, isAuditVisible, calculateFilterExpresion } from 'Utils/Utils';
import { getAnalyticalAccountsDataSource, getUOMsDataSource, getCurrenciesDataSource
    , getDebitCreditIndicatorsDataSource, getGoodsServicesIndicatorsDataSource, getCurrentCompanyProductsDataSource } from 'Utils/CommonDataSources';
import invoiceLineOrderReferencesDataSource from './InvoiceLineOrderReferences';
import invoiceLineTaxInformationDataSource from './InvoiceLineTaxInformation';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import InvoiceLineTaxInformationGrid from './_InvoiceLineTaxInformationGrid';
import InvoiceLineOrderReferencesGrid from './_InvoiceLineOrderReferencesGrid';

export default class InvoiceLineMasterDetail extends Component {

    constructor(props) {
        super(props)
        this.grdRef = React.createRef();
        this.nameRef = React.createRef();
        this.state = {
            InvoiceLineId: props.InvoiceLineId,
            DefaultReportCurrencyId: props.DefaultReportCurrencyId
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "InvoiceLineOrderReferences");
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.renderTaxInformation = () => { return <InvoiceLineTaxInformationGrid InvoiceLineId={this.props.InvoiceLineId} DefaultReportCurrencyId={this.state.DefaultReportCurrencyId} /> }
        this.renderOrderReferences = () => { return <InvoiceLineOrderReferencesGrid InvoiceLineId={this.props.InvoiceLineId} /> }
    }

    render() {
        return (
                <TabPanel width="95vw">
                    <Item title="Taxe" render={this.renderTaxInformation} />
                    <Item title="Referinte de comanda" render={this.renderOrderReferences} />
                </TabPanel>
        );
    }
}