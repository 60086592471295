import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { validatePhoneNumber, onRowUpdating, isAuditVisible, calculateFilterExpresion } from "Utils/Utils";
import { DataGrid } from 'devextreme-react';
import saleInvoicesDataSource from './SaleInvoicesData';
import { getPartenersDataSource, getAnalyticalAccountsDataSource, getInvoiceTypesDataSource, getSelfBillingIndicatorsDataSource, getReportShortTransactionsDataSource } from 'Utils/CommonDataSources';
import SaleInvoiceMasterDetail from './MasterDetail/_SaleInvoiceMasterDetail';
import { getReportDefaultCurrencyIdAsync } from 'Utils/CommonDataSources';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    EmailRule,
    FormItem,
    Form,
    Popup,
    CustomRule,
    StringLengthRule,
    Texts,
    Lookup,
    FilterRow,
    Scrolling,
    MasterDetail,
    NumericRule,
    RangeRule,
    SearchPanel,
    Paging,
    Pager
} from 'devextreme-react/data-grid';

export class SaleInvoicesGrid extends Component {
    constructor(props){
        super(props);
        this.grdSaleInvoicesRef = React.createRef();

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "SaleInvoices");
        }

        this.ReportId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

        this.onInitNewRow = (e) => {
            e.data.SelfBillingIndicatorId = 1;
        }

        this.getCurrentYear = () => {
            return new Date().getFullYear();
        }

        this.DefaultReportCurrencyId = null;
    }

    render() {
        return(
                <DataGrid
                    ref={this.grdSaleInvoicesRef}
                    dataSource={saleInvoicesDataSource(this.ReportId)}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    height="100%"
                    width="auto"
                    noDataText="Nu exista inregistrari"
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                    onInitNewRow={this.onInitNewRow}
                >
                    <FilterRow visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Search..." />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} infoText="Page {0} of {1} ({2} rows)" />
                    <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                        <Form colCount={2} title="Factura de vanzare" />
                        <Popup title="Detalii factura de vanzare" showTitle={true} minWidth="40em" maxWidth="80em" height="auto" />
                    </Editing>
                    <MasterDetail
                            enabled={true} 
                            component={props => {
                                return <SaleInvoiceMasterDetail SaleInvoiceId={props.data.key} DefaultReportCurrencyId={this.DefaultReportCurrencyId} />
                            }}
                        />
                    <Column type="buttons" width={120} minWidth="10em" fixed={true} fixedPosition="left">
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="InvoiceNo" caption="Numar" dataType="string" minWidth="5em" fixed={true} fixedPosition="left"
                        calculateSortValue="Invoice.InvoiceNo"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.InvoiceNo", selectedFilterOperations, filterValue)}}>
                        <StringLengthRule max={70} message="Numarul trebuie sa aiba maxim 70 de caractere" />
                        <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="InvoiceDate" caption="Data" minWidth="5em" fixed={true} fixedPosition="left"
                        dataType="date" format="dd.MM.yyyy"
                        calculateSortValue="Invoice.InvoiceDate"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.InvoiceDate", selectedFilterOperations, filterValue)}}>
                            <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="CustomerId" caption="Cumparator" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="Invoice.Customer.Company.Name" fixed={true} fixedPosition="left" editorOptions={{noDataText: "Nu exista date"}}
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.CustomerId", selectedFilterOperations, filterValue)}}>
                                <Lookup
                                    dataSource={getPartenersDataSource()}
                                    valueExpr="Id" 
                                    displayExpr="Name"
                                    searchExpr="Company.Name"
                                />
                                <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="Supplier" caption="Vanzator" cssClass="vertical-left" alignment="left" minWidth="10em"
                        allowEditing={false} allowFiltering={false} allowSorting={false}
                        fixed={true} fixedPosition="left">
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="AccountId" caption="Cont contabil" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="Invoice.Account.AccountId" editorOptions={{noDataText: "Nu exista date"}}
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.AccountId", selectedFilterOperations, filterValue)}}>
                                <Lookup
                                    dataSource={getAnalyticalAccountsDataSource()}
                                    valueExpr="Id" 
                                    displayExpr="AccountId"
                                />
                                <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="InvoiceTypeId" caption="Tip factura" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="Invoice.InvoiceType.Name" editorOptions={{noDataText: "Nu exista date"}}
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.InvoiceTypeId", selectedFilterOperations, filterValue)}}>
                                <Lookup
                                    dataSource={getInvoiceTypesDataSource()}
                                    valueExpr="Id" 
                                    displayExpr="Name"
                                />
                    </Column>
                    <Column dataField="Period" caption="Perioada" minWidth="10em" editorType="dxNumberBox" editorOptions={{format: "#"}}
                        calculateSortValue="Invoice.Period"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.Period", selectedFilterOperations, filterValue)}}>
                        <NumericRule message="Valoarea trebuie sa fie numerica!" />
                        <RangeRule min={0} max={1000} message="Valoarea trebuie sa fie intre 0 si 1000" />
                    </Column>
                    <Column dataField="PeriodYear" caption="An perioada" minWidth="10em" editorType="dxNumberBox" editorOptions={{format: "#"}}
                        calculateSortValue="Invoice.PeriodYear"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.PeriodYear", selectedFilterOperations, filterValue)}}>
                        <NumericRule message="Valoarea trebuie sa fie numerica!" />
                        <RangeRule min={2020} max={this.getCurrentYear()} message="Valoarea trebuie sa fie cuprinsa intre 2020 si anul curent" />
                    </Column>
                    {/* <Column dataField="ShipTo" caption="Expediere spre?" minWidth="10em" allowFiltering={false} allowSorting={false}>
                    </Column>
                    <Column dataField="ShipFrom" caption="Expediere de la?" minWidth="10em" allowFiltering={false} allowSorting={false}>
                    </Column> */}
                    <Column dataField="BranchStoreNumber" caption="Numar filiala magazin" dataType="string" minWidth="10em"
                        calculateSortValue="Invoice.BranchStoreNumber"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.BranchStoreNumber", selectedFilterOperations, filterValue)}}>
                        <StringLengthRule max={35} message="Numarul trebuie sa aiba maxim 35 de caractere" />
                    </Column>
                    <Column dataField="PaymentTerms" caption="Termeni plata" dataType="string" minWidth="10em"
                        calculateSortValue="Invoice.PaymentTerms"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.PaymentTerms", selectedFilterOperations, filterValue)}}>
                        <StringLengthRule max={70} message="Campul termeni plata trebuie sa aiba maxim 70 de caractere" />
                    </Column>
                    <Column dataField="SelfBillingIndicatorId" caption="Indicator autofacturare" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="Invoice.SelfBillingIndicator.Description" editorOptions={{noDataText: "Nu exista date"}}
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.SelfBillingIndicatorId", selectedFilterOperations, filterValue)}}>
                                <Lookup
                                    dataSource={getSelfBillingIndicatorsDataSource()}
                                    valueExpr="Id" 
                                    displayExpr="Description"
                                />
                    </Column>
                    <Column dataField="SourceId" caption="ID sursa" dataType="string" minWidth="10em"
                        calculateSortValue="Invoice.SourceId"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.SourceId", selectedFilterOperations, filterValue)}}>
                        <StringLengthRule max={35} message="ID-ul sursei trebuie sa aiba maxim 35 de caractere" />
                    </Column>
                    <Column dataField="GLPostingDate" caption="Data afisarii GL" minWidth="10em"
                        dataType="date" format="dd.MM.yyyy"
                        calculateSortValue="Invoice.GLPostingDate"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.GLPostingDate", selectedFilterOperations, filterValue)}}>
                    </Column>
                    <Column dataField="BatchId" caption="ID lot" dataType="string" minWidth="10em"
                        calculateSortValue="Invoice.BatchId"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.BatchId", selectedFilterOperations, filterValue)}}>
                        <StringLengthRule max={35} message="ID-ul filialei trebuie sa aiba maxim 35 de caractere" />
                    </Column>
                    <Column dataField="SystemId" caption="ID sistem" dataType="string" minWidth="10em"
                        calculateSortValue="Invoice.SystemId"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.SystemId", selectedFilterOperations, filterValue)}}>
                        <StringLengthRule max={35} message="ID-ul sistemului trebuie sa aiba maxim 35 de caractere" />
                    </Column>
                    {/* <Column dataField="TransactionIdentifier" caption="Id tranzactie" minWidth="10em"
                        calculateSortValue="Invoice.TransactionIdentifier"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.TransactionIdentifier", selectedFilterOperations, filterValue)}}>
                        <StringLengthRule max={70} message="ID-ul tranzactiei trebuie sa aiba maxim 70 de caractere" />
                    </Column> */}
                    <Column dataField="TransactionId" caption="Tranzactie" cssClass="vertical-left" alignment="left" minWidth="10em"
                            calculateSortValue="Invoice.Transaction.TransactionId" editorOptions={{noDataText: "Nu exista date"}}
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.TransactionId", selectedFilterOperations, filterValue)}}>
                                <Lookup
                                    dataSource={getReportShortTransactionsDataSource(this.ReportId)}
                                    valueExpr="Id" 
                                    displayExpr="TransactionId"
                                />
                    </Column>
                    <Column dataField="ReceiptNumbers" caption="Numere de chitanta" dataType="string" minWidth="10em"
                        calculateSortValue="Invoice.ReceiptNumbers"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.ReceiptNumbers", selectedFilterOperations, filterValue)}}>
                        <StringLengthRule max={256} message="Receipt Numbers trebuie sa aiba maxim 256 de caractere" />
                    </Column>
                    <Column dataField="InvoiceDocumentTotalsShippingCostsAmountTotal" caption="Cost total de transport" allowEditing={false} minWidth="10em"
                        calculateSortValue="Invoice.InvoiceDocumentTotalsShippingCostsAmountTotal"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.InvoiceDocumentTotalsShippingCostsAmountTotal", selectedFilterOperations, filterValue)}}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="InvoiceDocumentTotalsNetTotal" caption="Net Total" allowEditing={false} minWidth="10em" allowFiltering={false}
                        calculateSortValue="Invoice.InvoiceDocumentTotalsNetTotal"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.InvoiceDocumentTotalsNetTotal", selectedFilterOperations, filterValue)}}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="InvoiceDocumentTotalsGrossTotal" caption="Total brut" allowEditing={false} minWidth="10em"
                        calculateSortValue="Invoice.InvoiceDocumentTotalsGrossTotal"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Invoice.InvoiceDocumentTotalsGrossTotal", selectedFilterOperations, filterValue)}}>
                        <FormItem visible={false} />
                    </Column>
                </DataGrid>
        )
    }

    async componentDidMount() {  
        try {
            this.DefaultReportCurrencyId = await getReportDefaultCurrencyIdAsync(this.ReportId);
        } catch(err) {}
    
        //b(); // after function a finished, this function will calls
    }
}