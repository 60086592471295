import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { onRowUpdating, isAuditVisible } from "Utils/Utils";
import { DataGrid } from 'devextreme-react';
import { companyTaxRegistrationsDataSource } from '../_CompanyMasterDetailDataSources';
import { taxTypesDataSource } from './_CompanyTaxRegistrationsDataSources';
import {
    Button,
    Column,
    Editing,
    RequiredRule,
    EmailRule,
    FormItem,
    Form,
    Popup, 
    Lookup,
    StringLengthRule,
    Texts
} from 'devextreme-react/data-grid';

export class CompanyTaxRegistrationsGrid extends Component {
    constructor(props){
        super(props);
        this.grdTaxRegistrationsRef = React.createRef();
        this.state = {
            CompanyId: props.CompanyId
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "CompanyTaxRegistrations");
        }
    }

    render() {
        return(
            <div className="container-master-detail">
                <DataGrid
                    ref={this.grdTaxRegistrationsRef}
                    width="100%"
                    dataSource={companyTaxRegistrationsDataSource(this.state.CompanyId)}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    height="100%"
                    noDataText="Nu exista inregistrari"
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                >
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Cont bancar" />
                        <Popup title="Detalii taxe" showTitle={true} minWidth="20em" maxWidth="40em" height="auto" />
                    </Editing>
                    <Column type="buttons" width={120}>
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        <Texts saveRowChanges="Salveaza" cancelRowChanges="Renunta" />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="TaxTypeId" caption="Tip" editorOptions={{noDataText: "Nu exista date"}}>
                        <Lookup dataSource={taxTypesDataSource()} valueExpr="Id" displayExpr="Name" />
                        <RequiredRule message="Camp obligatoriu" />
                    </Column>
                    <Column dataField="TaxNumber" caption="Numar?">
                        <StringLengthRule max={35} message="Numarul trebuie sa aiba maxim 35 de caractere" />
                    </Column>
                    <Column dataField="TaxAuthority" caption="Autoritate?">
                        <StringLengthRule max={35} message="Autoritatea trebuie sa aiba maxim 35 de caractere" />
                    </Column>
                    <Column dataField="TaxVerificationDate" caption="Data verificare?" 
                        dataType="date" format="dd.MM.yyyy">
                    </Column>
                </DataGrid>
            </div>
        )
    }
}