import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { SelectBox } from 'devextreme-react';
import { getCompaniesDataSource } from '../_UserMenuDataSources'; 

export class CompanySelection extends Component {
    static displayName = CompanySelection.name;

    constructor(props){
        super(props);

        this.state = {
            refresh: false
        }

        this.onValueChanged = (e) => {
            localStorage.setItem("CurrentCompany", e.value.toString())
            if(e.previousValue != e.value){
                window.location.reload(false);
            }
        }

        this.setCompany = null;

        this.selectBoxRef = React.createRef();
    }

    render(){
        return(
            <SelectBox
                ref={this.selectBoxRef}
                dataSource={getCompaniesDataSource(this.selectBoxRef)}
                displayExpr="Name"
                valueExpr="Id"
                noDataText="Nu exista companii asociate cu acest cont"
                onInitialized={(e) => { if(!e.component.option("value")) { e.component.getDataSource().load(); }}}
                onValueChanged={this.onValueChanged}
            >
            </SelectBox>
        )
    }

    componentDidMount(){
        const {setCompany} = this.props;
        this.setCompany = setCompany;
    }

    componentDidUpdate(){
        const {setCompany} = this.props;
        this.setCompany = setCompany;
    }
}